.mainPageWrap {
  .stepContainer {
    margin-top: 0.5rem;
    background-color: @white;
    height:calc(100% - 96px);
    position: relative;
    overflow: auto;

    form {
      padding: 2.5rem 5rem 10rem 5rem;
      height: 100%;
      overflow: auto;

      .ant-form-item {
        margin-bottom: 15px;

        .ant-form-item-row {
          display: flex;
          flex-direction: column;

          .ant-form-item-label {
            text-align: start;
            white-space: inherit !important;
            width: 100%;

            label {
              font-size: 1.4rem;
              color: @font-color;
              font-weight: 400;
              font-family: @primary-font;
              white-space: inherit;
              height: inherit;
              align-items: flex-start;
              min-height: 33px;

              @media screen and (max-width:1680px) {
                font-size: 1.3rem;
                min-height: 40px;
              }
            }
          }
        }

        .ant-form-item-control {
          .ant-form-item-control-input {
            min-height: max-content;
          }

          .ant-form-item-control-input-content {
            min-height: inherit;

            .ant-select {
              .ant-select-selector {
                background-color: @input-color;
                border-radius: 4px;
                padding: 0 16px;

              }

              &.ant-select-focused {
                border-radius: 4px;
                border: 1px solid @secondary-color !important;

              }

              .ant-select-arrow {
                background-color: @dropdown-color;
                border-radius: 4px 4px 0 0;
                height: 100%;
                width: 4.2rem;
                top: 0.6rem;
                right: 0;
                justify-content: center;
              }
            }

            .ant-checkbox-group {
              .ant-checkbox-wrapper {
                span {
                  font-size: 1.4rem;
                  color: #181c32;
                  font-weight: 400;
                  font-family: @primary-font;
                  text-transform: capitalize;
                }
              }
            }
          }

          .ant-input {
            background-color: @input-color !important;
            border-radius: 4px;
            padding: 0 12px;

            &:focus {
              border: 1px solid @secondary-color;
              border-radius: 4px;
            }
          }

          .ant-picker {
            background-color: @input-color;
            border-radius: 4px;
            padding: 0 12px;
          }
        }
      }

      .ant-col {
        &.commanCol {
          padding-top: 50px;
          padding-bottom: 12px;

          @media screen and (max-width:991px) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }


    }

    .ant-form {
      .ant-col {
        .ant-form-item-control {
          padding-bottom: 0rem;
          padding-top: 0.2rem;
          display: block;
          min-height: auto;
        }
      }
    }

    #SecondStep,
    #FirstStep,
    #ThirdStep,
    #FourthStep {
      .ant-form-item {
        .ant-form-item-explain {
          height: 29px;
        }

        .ant-form-item-explain-error {
          margin: 5px 1px;
        }

        .ant-form-item-row {
          margin: 0.2rem 0 0rem 0;
        }
      }

      .ant-col-8 {
        @media screen and(max-width:1350px) {

          flex: 0 0 50%;
          max-width: 50%;

        }

        @media screen and(max-width:991px) {

          flex: 0 0 100%;
          max-width: 100%;

        }
      }

      .ant-col-12 {
        @media screen and(max-width:991px) {

          flex: 0 0 100%;
          max-width: 100%;

        }
      }

      .ant-tooltip {
        display: none;
      }
    }

    #SecondStep,
    #ThirdStep,
    #FourthStep {
      .ant-form-item {
        height: auto;

        >.ant-form-item-row {
          height: 100%;
          justify-content: flex-end;

          .ant-form-item-control {
            flex: none;
          }

          .ant-form-item-control-input+div {
            position: absolute;
            bottom: -26px;
            width: 100%;
          }
        }
      }
    }


    .commanQue {
      display: flex;
      width: 100%;

      .ant-form-item {
        width: 100%;
      }

      svg {
        margin: 0.5rem 0 0 0.5rem;
      }

      .field-required {

        .ant-form-item-label {
          left: 14px;
          position: relative;
        }

        &::before {
          position: absolute;
          left: 18px;
          top: 16px;
          color: #ff4d4f;
          font-size: 14px;
          line-height: 1;
          content: "*";
        }
      }
    }

    .secondStep,
    .thirdStep {
      .ant-col-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }

      .ant-form-item {
        margin-bottom: 30px;
      }
    }

    .stepBtn {
      margin-top: 0;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      bottom: 0;
      background-color: @white;
      height: 75px;
      width: 100%;
      right: 40px;

      button {
        margin: 1rem 1rem 2rem 0;
      }
    }

    .stepBtnPile {
      margin-top: 0;
      display: flex;
      justify-content: flex-end;
      background-color: @white;
      height: 75px;
      width: 100%;

      button {
        margin: 1rem 1rem 2rem 0;
      }
    }

    .stepPileBtn {
      margin-top: 0;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      bottom: 0;
      background-color: #fff;
      width: 100%;
      right: 35px;
      z-index: 99;
      flex-wrap: wrap;

      button {
        margin: 1rem 1.5rem 2rem 0;

        @media screen and(max-width:870px) {
          margin: 1rem 0.5rem 2rem 0;

        }

      }

      @media screen and(max-width:870px) {
        right: 10px;

      }
    }

    // @media screen and (max-height:1200px) {
    //   height: 100%;

    // }

    // @media screen and (max-height:1100px) {
    //   height: 70vh;

    // }

    // @media screen and (max-height:1050px) {
    //   height: 66vh;

    // }

    // @media screen and (max-height:950px) {
    //   height: 65vh;

    // }
    // @media screen and (max-height:750px) {
    //   height: 60vh;

    // }
    
  }

  .primarySteps {
    width: max-content;

    .ant-steps-item {
      padding-inline-start: 0 !important;
      overflow: visible !important;

      .ant-steps-item-container {
        .ant-steps-item-icon {
          display: none;
        }
      }

      &.ant-steps-item-wait,
      &.ant-steps-item-process,
      &.ant-steps-item-finish {
        .ant-steps-item-content {
          display: flex;
          align-items: center;
          overflow: visible;
          width: 250px;

          .ant-steps-item-title {
            background-color: #a7a7a7;
            height: 7rem;
            width: 7rem;
            padding-right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 5px solid #fff;
            opacity: 1;
            box-shadow: 0 0 10px 0 rgb(0 0 0 / 35%);
            margin: 1rem 0;
            overflow: visible;
            isolation: isolate;

            h1 {
              margin-bottom: 0;
              text-align: center;
              font-size: 2.2rem;
              color: #fff;
              font-weight: 500;
              font-family: "Oswald", sans-serif;
              background-color: #a7a7a7;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              position: relative;
              z-index: 5;
            }

            &::after {
              width: 250px;
              height: 16px;
              top: 23px;
              left: 46px;
              border: 5px solid @white;
              box-shadow: 0 0 10px 0 rgb(0 0 0 / 35%);
              background-color: #a7a7a7;

              @media screen and(max-width:991px) {
                width: 150px;
              }
            }
          }

          @media screen and(max-width:1024px) {
            width: 231px;
          }

          @media screen and(max-width:991px) {
            width: 150px;
          }
        }
      }

      &.ant-steps-item-finish,
      &.ant-steps-item-process {
        .ant-steps-item-content {
          .ant-steps-item-title {
            h1 {
              background-color: @secondary-color;
            }

            &::after {
              background-color: @secondary-color;
            }
          }
        }
      }

      &.ant-steps-item-process {
        .ant-steps-item-content {
          .ant-steps-item-title {
            &::after {
              background-color: #a7a7a7;
            }
          }
        }
      }
    }

  }
}

.ant-select-dropdown {
  .ant-select-item {
    padding: 0.7rem 2rem;

    .ant-select-item-option-content {
      font-size: 1.4rem;
      color: #181c32;
      font-weight: 400;
      font-family: @primary-font;
    }
  }
}

// structure Element 
.structureDrawer {
  .ant-drawer-content-wrapper {
  width: 60% !important;
  @media screen and (max-width: 1350px) {
  width: 75% !important;
  }
  }
  
  .ant-form-item {
  height: 90px;
  margin-bottom: 30px;
  
  .ant-form-item-row {
    display: flex;
    flex-direction: column;
  
    .ant-form-item-label {
      text-align: start;
      white-space: inherit !important;
      margin-bottom: 5px;
  
      label {
        font-size: 1.4rem;
        color: @font-color;
        font-weight: 400;
        font-family: @primary-font;
        height: max-content;
  
        @media screen and(max-width:1460px) {
          font-size: 1.3rem;
        }
  
      }
  
    }
  }
  
  .ant-form-item-control {
    .ant-form-item-control-input-content {
      .ant-select {
        .ant-select-selector {
          background-color: @input-color;
          border-radius: 4px;
  
        }
  
        .ant-select-arrow {
          background-color: @dropdown-color;
          height: 100%;
          width: 4.2rem;
          top: 0.6rem;
          right: 0;
          justify-content: center;
        }
  
        &.ant-select-focused {
          border-radius: 4px;
          border: 1px solid @secondary-color !important;
  
        }
      }
  
      .ant-checkbox-group {
        .ant-checkbox-wrapper {
          span {
            font-size: 1.4rem;
            color: #181c32;
            font-weight: 400;
            font-family: @primary-font;
            text-transform: capitalize;
          }
        }
      }
  
      textarea {
        &.ant-input {
          margin-top: 10rem !important;
        }
      }
  
      .ant-input-number {
        background-color: @input-color;
        border-radius: 4px;
  
        &.ant-input-number-focused {
          border: 1px solid @secondary-color;
          border-radius: 4px;
        }
  
        .ant-input-number-input {
          font-size: 1.6rem;
          font-weight: 400;
          font-family: @primary-font;
        }
      }
    }
  
    .ant-input {
      background-color: @input-color;
      border-radius: 4px;
      font-size: 1.6rem;
      font-weight: 400;
      font-family: @primary-font;
  
      &:focus {
        border: 1px solid @secondary-color;
        border-radius: 4px;
      }
    }
  }
  
  .ant-form-item-explain {
    margin: 6px;
  }
  }
  
  .ant-form {
  .ant-col {
  .ant-form-item-control {
  padding-bottom: 3.5rem;
  padding-top: 0.2rem;
  }
  }
  }
  
  @media screen and (max-width: 1300px) {
  .ant-drawer-content-wrapper {
  width: 80% !important;
  }
  
  form {
    .ant-col-12 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  }
  .dateInStructure{
  margin-top: 40px;
  @media screen and (max-width: 1500px) {
    margin-top: 0;
  }
  }
}

.StructureIcon {
  &:hover {
    g {
      path {
        fill: @secondary-color;
        stroke: @secondary-color;
      }
    }
  }

}

.pileCount {
  font-family: @primary-font;
  color: #000;
  margin-left: 24px;
  margin-bottom: 5px;

  span {
    color: #000;
  }
  @media screen and (max-width:850px) {
    font-size: 12px;
    margin-left: 0;
  }
}

.ant-form-item-margin-offset {
  margin-bottom: 0 !important;
}

.pileNumber {
  color: #5e6278;
  font-size: 1.4rem;
  .semiMediumFonts();
}

// cube testing

.cubeReportForm {
  .ant-upload-list-item-done {
    .anticon-delete {
      display: none !important;
    }

    .anticon-eye {
      position: absolute;
      height: 30px;
    }
  }

  .ant-form-item {
    .ant-form-item-label {
      label {
        position: relative;
      }
    }
  }

  .reportDueDate {
    position: absolute;
    left: 115px;
    top: 2px;
    font-family: @primary-font;
    color: #ff4d4f;
    font-size: 14px;
    font-weight: 500;
  }
}



//  concreating

.Concform {
  background-color: @white;
  padding: 0rem 5rem 5.5rem 5rem;
  height: 100%;
  overflow: auto;

  .projectTitle {
    text-align: center;
    position: relative;
    margin: 50px 0;

    h2 {
      position: inherit;
      max-width: 400px;
      margin: 0 auto;
      z-index: 1;

      span {
        background-color: @secondary-color;
        padding: 5px 60px;
        border-radius: 40px;
        margin: 0 5px;
        font-family: @primary-font;
        font-size: 16px;
        color: @white;
        position: inherit;
      }
    }

    &::before {
      position: absolute;
      width: 50%;
      background-color: #cccccc;
      height: 1px;
      content: "";
      top: 9px;
      border-radius: 10px;
      z-index: 0;
      left: 0;
      top: 50%;
    }

    &::after {
      position: absolute;
      width: 50%;
      background-color: #cccccc;
      height: 1px;
      content: "";
      top: 9px;
      border-radius: 10px;
      z-index: 0;
      right: 0;
      top: 50%;
    }
  }

  .ant-row {
    margin: 40px 0 40px 0;
  }

  .commanQue {
    .ant-form-item-control {
      flex: unset !important;

    }

    .remarkText {
      .ant-row {
        margin: 0 0 10px 0 !important;
      }
    }

    textarea {
      resize: none;

      &.ant-input {
        padding: 5px 12px;
      }
    }

    .ant-upload-wrapper {
      .ant-tooltip {
        display: none !important;
      }
    }

    .ant-form-item-label {
      label {
        width: 90%;

      }
    }

    .ant-form-item {
      width: 100%;
    }

  }

  .ant-row {
    .ant-row {
      margin: 15px 0;
    }
  }

  .ant-form-item {
    margin-bottom: 15px;

    .ant-form-item-row {
      display: flex;
      flex-direction: column;

      .ant-form-item-label {
        text-align: start;
        white-space: inherit !important;

        label {
          font-size: 1.4rem;
          color: @font-color;
          font-weight: 400;
          font-family: @primary-font;
          white-space: inherit;
          height: inherit;
          align-items: flex-start;

          @media screen and (max-width:1600px) {
            font-size: 1.3rem;
          }
        }
      }
    }

    .ant-form-item-control {
      .ant-form-item-control-input {
        min-height: max-content;
      }

      .ant-form-item-control-input-content {
        min-height: inherit;

        .ant-select {
          .ant-select-selector {
            background-color: @input-color;
            border-radius: 4px;
            padding: 0 16px;

          }

          &.ant-select-focused {
            border-radius: 4px;
            border: 1px solid @secondary-color !important;

          }

          .ant-select-arrow {
            background-color: @dropdown-color;
            border-radius: 4px 4px 0 0;
            height: 100%;
            width: 4.2rem;
            top: 0.6rem;
            right: 0;
            justify-content: center;
          }
        }

        .ant-checkbox-group {
          .ant-checkbox-wrapper {
            span {
              font-size: 1.4rem;
              color: #181c32;
              font-weight: 400;
              font-family: @primary-font;
              text-transform: capitalize;
            }
          }
        }
      }

      .ant-input {
        background-color: @input-color !important;
        border-radius: 4px;
        padding: 0 12px;

        &:focus {
          border: 1px solid @secondary-color;
          border-radius: 4px;
        }
      }

      .ant-picker {
        background-color: @input-color;
        border-radius: 4px;
        padding: 0 12px;
      }
    }
  }

  .textAreaResize {
    textarea {
      resize: none;

      &.ant-input {
        padding: 5px 12px;
      }
    }

    .ant-form-item-control {
      margin-bottom: 30px;
    }

    .ant-row {
      margin: 0 0 0 0;
    }
  }

  .radioGap {
    margin-bottom: 0;

    //  for aligment 
    .ant-form-item-label {
      height: 52px;
      max-height: max-content;
    }

    .ant-form-item-control {
      height: 40px;
      max-height: max-content;

      @media screen and (max-width:1180px) {
        height: max-content;
      }
    }

  }

  @media screen and(max-width:1399px) {
    .ant-col-8 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .ant-col-9 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  @media screen and(max-width:1120px) {
    .ant-col-8 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @media screen and (max-width:991px) {
    .ant-col-12 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }

  }


}

// menu dropdown in pile listing
.menuOptions {
  .ant-dropdown-menu {
    max-width: 200px;
    width: 200px;
    padding: 5px 5px;

    .ant-dropdown-menu-item {
      padding: 0 0;

      .ant-dropdown-menu-title-content {
        button {
          width: 100%;
          border: none;
          box-shadow: none;
          max-width: 200px;

          span {
            font-size: 1.5rem;
            font-family: @primary-font;
          }
        }

        .ant-divider {
          margin: 3px 0;
        }

      }
    }
  }
}

// custom form steps

.mainStep {
  margin-top: 2.5rem;

  .steps {
    background-color: #a7a7a7;
    padding: 1.2rem 2.9rem;
    margin: 0 12rem 0 0;
    border-radius: 50%;
    position: relative;
    border: 5px solid @white;
    font-family: "Oswald", sans-serif;
    font-size: 2.2rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.35);
    color: @white;
    z-index: 999;
    isolation: isolate;


    .title {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 50;
      margin: 0 auto;
      font-size: 22px;
      // padding: 1.2rem 1.9rem;
      height: 56px;
      width: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      isolation: isolate;


      &.currentSteps,
      &.activeSteps {
        z-index: 999;
        isolation: isolate;



        .key {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 100%;
          background-color: #a7a7a7;
          transition: all 0.5s ease-in-out;
          position: relative;
          z-index: 5;
          display: grid;
          place-items: center;

          @media screen and (max-width:991px) {
            font-size: 20px;
          }
        }


        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 37%;
          right: 50px;
          z-index: -20;
          width: 137px;
          height: 14px;
          border: 4px solid @white;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.35);
          background-color: #a7a7a7;

          @media screen and (max-width:1570px) {
            width: 95px;
          }

          @media screen and (max-width:1360px) {
            width: 64px;
          }

          @media screen and (max-width:1180px) {
            width: 54px;
          }

          @media screen and (max-width:1100px) {
            width: 32px;
            right: 42px;
          }

          @media screen and (max-width:890px) {
            width: 25px;
            right: 39px;
          }
          @media screen and (max-width:780px) {
            width: 20px;
            right: 35px;
          }

        }
      }

      &.activeSteps {
        .key {
          background-color: @secondary-color;
        }

        &::before {
          background-color: @secondary-color;
        }
      }

      @media screen and (max-width:1180px) {
        height: 45px;
        width: 45px;
      }

      @media screen and (max-width:1100px) {
        height: 38px;
        width: 38px;
      }
    }

    &:first-child {
      .title {
        &::before {
          display: none;
        }
      }
    }

    @media screen and (max-width:1570px) {
      margin: 0 8rem 0 0;
    }

    @media screen and (max-width:1360px) {
      margin: 0 5rem 0 0;
    }

    @media screen and (max-width:1180px) {
      padding: 0.7rem 2.3rem;
    }

    @media screen and (max-width:1100px) {
      margin: 0 3rem 0 0;
      padding: 0.3rem 1.9rem;
    }

    @media screen and (max-width:890px) {
      margin: 0 2rem 0 0;
    }
    @media screen and (max-width:780px) {
      margin: 0 0.8rem 0 0;
    }

  }
}

// main wrapper
.structureElementWrapper{
  height: calc(100% - 30px);
}