.consolidTable {
  // margin: 4rem 0;

  .formHeading {
    h3 {
      bottom: -1px;
      padding: 8px 40px;
      border: none;
      border-radius: 17px 17px 0 0;
    }
  }

  .subHeadingWrap {
    border: none !important;
    padding: 10px 0;

    td {
      padding: 0 45px;
      border: none !important;
    }

    .subRowHeading {
      background: #f8f8f8;
      width: 100%;
      vertical-align: baseline;
      text-align: left;
      padding: 10px 15px;
      border: 1px solid #d1d3d3;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .actionBtn {
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        padding: 0 0;
        height: 35px;
        box-shadow: none;

        span {
          font-size: 1.3rem;
          font-family: @primary-font;
          font-weight: 500;
          margin-left: 0.5rem;
          color: @secondary-color;
        }

        svg {
          height: 18px;
          width: 18px;

          path {
            stroke: @secondary-color;
          }
        }

        &:hover {
          span {
            color: @primary-color;
          }

          svg {
            path {
              stroke: @primary-color;
            }
          }
        }
      }

      span {
        color: #353d40;
        font-family: @primary-font;
        font-size: 14px;
        font-weight: 600;
      }

      .viewBtn {
        border: none;
        display: table-cell;
        vertical-align: middle;

        .actionBtn {
          display: flex;
          align-items: center;
          border: none;
          background-color: transparent;
          outline: none;
          height: 35px;
          box-shadow: none;

          svg {
            width: 23px;
            height: 23px;

            path {
              fill: @secondary-color;
            }
          }

          span {
            margin-left: 1rem;
            color: #3e97ff;
            font-family: @primary-font;
            font-size: 14px;
            font-weight: 500;
          }

          &:hover {
            span {
              color: @primary-color;
            }

            svg {
              path {
                fill: @primary-color;
              }
            }
          }
        }
      }
    }
  }

  table {
    width: 100%;
    table-layout: fixed;
    margin: 0;
    padding: 2rem;
    background-color: @white;
    border-radius: 20px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 991px) {
      border: 0 solid rgba(@secondary-color, 0.2);
    }

    thead {
      position: relative;
      clip: rect(1px, 1px, 1px, 1px);

      tr {
        text-align: center;

        td {
          border-right: 1px solid #e2e2e2;
          padding: 1rem 1.2rem;
          color: #353d40;
          font-family: @primary-font;
          font-size: 1.3rem;
          font-weight: 500;
          border-bottom: 1px solid transparent;

          @media screen and(max-width:1440px) {
            padding: 10px 5px;
          }

          @media screen and(max-width:1200px) {
            font-size: 1.2rem;
          }

          @media screen and (max-width: 991px) {
            display: block;
          }

          .listHeader {
            padding: 2rem 3rem;

            @media screen and(max-width:1200px) {
              padding: 2rem 1rem;
            }
          }

          &:last-child {
            border-right: none;
          }
        }

        @media screen and (max-width: 991px) {
          display: block;
        }
      }

      @media screen and (max-width: 991px) {
        position: absolute;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
      }

      .testRow {
        position: relative;

        &:after {
          position: absolute;
          content: "";
          height: 1px;
          left: 0;
          width: 100%;
          background-color: #e2e2e2;
          bottom: 0;
        }
      }

      .testSubRow {
        td {
          width: 130px;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 14px;
          border-right: 1px solid #e2e2e2;
          color: #353d40;
          font-family: @primary-font;
          font-size: 1.3rem;
          font-weight: 400;
          word-wrap: break-word;
          padding: 10px 25px;
          text-align: center;

          @media screen and(max-width:1440px) {
            font-size: 1.3rem;
            padding: 10px 5px;
          }

          @media screen and (max-width: 991px) {
            display: flex;
            justify-content: space-between;

            padding: 1rem 3rem 1rem 1rem;
            border-right: 0;

            &::before {
              content: attr(data-label);
            }

            span {
              text-align: right;
            }

            &:nth-child(even) {
              background-color: rgba(rgb(37, 34, 34), 0.04);

            }
          }

          @media screen and (max-width: 600px) {
            font-size: 1.2rem;
          }

          &:last-child {
            border-right: none;
          }
        }

        @media screen and (max-width: 991px) {
          border: 1px solid rgba(rgb(185, 185, 185), 0.2);
          display: block;
          border-radius: 7px;
        }

        &:last-child {
          border-bottom: none !important;
        }

        &.bodyRowHeading {
          position: relative;

          &:after {
            position: absolute;
            content: "";
            background-color: #e2e2e2;
            height: 1px;
            width: 93%;
            left: 0;
            right: 0;
            margin: 0 auto;
          }

          .firstRow {
            text-align: left;
            padding: 10px 35px 10px 65px;

            @media screen and (max-width:991px) {
              color: @primary-color;
              font-weight: 500;
            }
          }

          td {
            padding-right: 10px;
          }

          .approved {
            div {
              height: 24px;
              width: 25px;
              max-width: auto;
              left: 0;
              right: 0;
              position: relative;
              margin: 0 auto;

              span {
                border: 1px solid rgb(6, 203, 6);
                position: absolute;
                content: "";
                height: 24px;
                width: auto;
                left: 0;
                right: 0;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                color: rgb(6, 203, 6);
                background-color: rgba(6, 203, 6, 0.2);
              }

              @media screen and (max-width:991px) {
                margin: 0;
                left: 4px;
              }
            }

          }

          .failed {
            color: #df0f0f;
            font-weight: 500;

            div {
              height: 24px;
              width: 25px;
              max-width: auto;
              left: 0;
              right: 0;
              position: relative;
              margin: 0 auto;

              span {
                border: 1px solid #df0f0f;
                position: absolute;
                content: "";
                height: 24px;
                width: auto;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                right: 0;
                margin: 0 auto;
                border-radius: 50%;
                background-color: rgba(224, 16, 16, 0.2);
              }
            }
          }
        }
      }
    }
  }
}