.main__page__appheader {
  display: flex;
  align-items: center;
  padding-inline: inherit !important;
  padding: 8px 10px !important;
  line-height: normal;
  background-color: @layout-body-background !important;
  border-bottom: 1px solid #e8e8e8;
  height: auto;

  .headerWrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .appheader__right {
    margin-left: auto;

    .userDropdown {
      .userMenuWrap {
        display: flex;
        align-items: center;
        cursor: pointer;

        >span {
          margin-left: 10px;
        }

        .avatar img {
          width: 35px;
          height: 35px;
          background-color: transparent;
          border-radius: 8px;
        }

        .userName {
          font-weight: 400;
          font-family: @primary-font;
          font-size: 1.4rem;
        }

        .anticon {
          font-size: 12px;
        }

        .ModeBtn {
          margin: 1rem;
        }
      }
    }
  }

  .appheader__left {
    display: flex;
    align-items: center;

    .menuToggle {
      .icon {
        width: 35px;
        height: 35px;
        background-color: @white;
        position: relative;
        border-radius: 8px;
        z-index: 999;
        cursor: pointer;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

        span {
          background-color: @text_dark_black;
          height: 2px;
          position: absolute;
          left: 8px;
          transition: all 0.5s ease;

          &:first-child {
            width: 18px;
            top: 11px;
          }

          &:nth-child(2) {
            width: 12px;
            top: 17px;
          }

          &:last-child {
            width:7px;
            top: 23px;
          }
        }

        &.right span {
          right: 11px;
          left: auto;
        }
      }
    }

    >div {
      margin-right: 20px;
    }

    .syncMenu button {
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      padding: 0;
      color: @white;
      background-color: @primary-color;
      border: 1px solid @primary-color;
      font-size: 16px;

      svg {
        width: 20px;
        height: 20px;

        path {
          fill: @white;
        }
      }
    }

    .adminDropdown {
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 0;
        background-color: @white;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }

  .sidebar__trigger {
    cursor: pointer;
  }
  // @media screen and (max-width:1200px) {
  //   height: 90px;
  // }
  // @media screen and (max-width:921px) {
  //   height: 100px;
  // }
}