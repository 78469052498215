.cardWrapper {
  // margin: 2rem 0;

  .cardWrap {
    padding: 4rem 2rem 2rem 2rem;
    max-height: 380px;
    height: 100%;
    margin: 0 15px 0 0;
    border-radius: 5px;
    .transition(0.5s, linear);
    cursor: pointer;

    img {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 0;
      opacity: 0;
      .transition(0.5s, ease-in-out);
    }

    .cardContent {
      width: 75%;

      h2 {
        color: #182125;
        font-family: @primary-font;
        font-size: 1.9rem;
        font-weight: 500;
        position: relative;
        margin-top: 2.4rem;
        .transition(0.5s, ease-in-out);

        @media screen and (max-width: 1350px) {
          font-size: 1.6rem;
        }
      }

      .cardIcon {
        background-color: #efefef;
        height: 80px;
        width: 80px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .transition(0.5s, ease-in-out);
      }
    }

    .arrowIcon {
      height: 75px;
      width: 75px;
      background: #efefef;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      bottom: 0;
      border-radius: 45px 0 0 0;
      .transition(0.5s, linear);
    }

    &:hover {
      background-color: @card-bg-color;
      box-shadow: 0 0 25px 10px rgba(40, 40, 40, 0.25);

      img {
        opacity: 1;
      }

      h2 {
        color: @white;
      }

      .arrowIcon {
        background-color: #ececec;

        svg {
          transform: rotate(-90deg);
        }
      }

      .cardIcon {
        box-shadow: 0 1px 5px 20px rgba(229, 223, 223, 0.1);
      }
    }

    @media screen and (max-width: 1450px) {
      padding: 4rem 1rem 2rem 1rem;
    }
  }

  .ant-card {
    @media screen and (max-width: 1250px) {
      padding: 2rem 1rem 2rem 1rem;
    }

    @media screen and (max-width:950px) {
      padding: 1rem 1rem;

      .ant-card-body {
        padding: 15px;
      }
    }
  }

  .ant-col-6 {
    @media screen and (max-width: 1200px) {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      margin-bottom: 2rem;
    }

    @media screen and (max-width:991px) {
      flex: 0 0 50%;
      max-width: 50%;
      margin-bottom: 2rem;
    }
  }
}

// new dashboard
.test {
  width: calc(100% - 50px);
  background-color: white;
  border-radius: 8px;
  height: 100%;
}

.departmentTable {
  height: 100%;

  table {
    table-layout: auto !important;
  }

  .ant-table {
    border: 1px solid #d1d3d3;
  }

  .ant-table-container {
    .ant-table-thead {
      tr {
        td {
          &:first-child {
            background-color: @white;
            border-bottom: 1px solid #d1d3d3;
          }

          padding: 0 0;
          min-width: 295px;
          max-width: 295px;

          @media screen and (max-width: 991px) {
            min-width: 140px;
            max-width: 140px;
          }

          &.ant-table-cell-scrollbar {
            max-width: 0;
            min-width: 0;
            width: 0;
          }
        }

        th {
          min-width: 298px;
          max-width: 298px;

          &::before {
            top: 57%;
            height: 100% !important;
          }

          @media screen and (max-width: 991px) {
            min-width: 140px;
            max-width: 140px;
          }
        }
      }
    }

    .ant-table-body {
      height: 100%;

      tr {
        td {
          padding-left: 16px !important;
          padding-right: 16px !important;
          border-top: 1px solid #d1d3d3;
          // border-bottom: 1px solid black;
          // border: none;
          border-right: none;

          &:nth-child(1) {
            border: none;
            box-shadow: 8px 0 10px 0 rgba(206, 206, 206, 0.25);
            border: none;
            border-right: 1px solid #d1d3d3;
            position: relative;
            border-left: 0 !important;

            &::after {
              position: absolute;
              background: #d1d3d3;
              height: 1px;
              width: 100%;
              content: "";
              left: -100%;
              bottom: 0;
            }
          }

          &:nth-child(2) {
            border-right: 1px solid #d1d3d3;
          }

          &.ant-table-cell {
            height: 44px;
            min-width: 299px;
            max-width: inherit;
            word-wrap: break-word;
            word-break: break-all;

            // width: 295px;
            &.ant-table-cell-fix-left {
              min-width: 295px;
              max-width: 295px;
              word-wrap: break-word;
              width: 295px;

              @media screen and (max-width: 991px) {
                min-width: 140px;
                max-width: 140px;
              }
            }

            @media screen and (max-width: 991px) {
              min-width: 140px;
              max-width: 140px;
            }
          }

          &.ant-table-cell {
            border-left: 1px solid #e2e2e2;
            border-right: 0;

            .expand-icon {

              &.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover,
              &.ant-btn:not(:disabled):focus-visible {
                outline: 0 !important;
              }
            }
          }
        }
      }

      .titleRow {
        td {
          &.ant-table-cell {
            border-left: 0;
            font-weight: 500 !important;
            font-size: 1.6rem;
            font-family: @primary-font;
            color: #000;
          }
        }
      }
    }
  }

  .departmentTableTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    color: #353d40;
    font-family: @primary-font;
    font-size: 1.6rem;
    font-weight: 600;

    span {
      word-break: break-word;
      margin-right: 5px;
    }

    .iconCol {
      display: flex;
      flex-direction: column;

      svg {
        width: 18px;
        height: 18px;
      }

      button {
        height: 26px;
        padding: 3px 3px;
        margin: 0 0 5px 0;
        border: none;
      }

      .deparmentDashboardIcon {
        background-color: @secondary-color;
        border: 1px solid transparent;

        svg {
          path {
            stroke: @white;
            stroke-width: 50;
          }
        }

        &:hover {
          background-color: transparent;
          border: 1px solid @secondary-color;

          svg {
            path {
              stroke: @secondary-color;
            }
          }
        }
      }

      .deparmentDeleteIcon {
        background-color: #fd4c52;
        border: 1px solid transparent;

        svg {
          path {
            stroke: @white;
            stroke-width: 2;
          }
        }

        &:hover {
          background-color: transparent;
          border: 1px solid #fd4c52;

          svg {
            path {
              stroke: #fd4c52;
            }
          }
        }
      }
    }

    @media screen and (max-width: 991px) {
      font-size: 1.5rem;
    }
  }

  .ant-table {
    .ant-table-body {
      table {
        .ant-table-tbody {
          tr {
            td {
              text-align: left !important;
            }
          }
        }
      }
    }
  }

  .ant-table-container {
    .ant-table-body {
      height: calc(75vh - 15px);

      @media screen and (max-height: 1390px) {
        height: calc(80vh - 1px);
      }

      @media screen and (max-height: 1240px) {
        height: calc(77vh - 1px);
      }

      @media screen and (max-height: 1100px) {
        height: calc(75vh - 0px);
      }

      @media screen and (max-height: 1080px) {
        height: calc(75vh - 15px);
      }

      @media screen and (max-height: 920px) {
        height: calc(73vh - 30px);
      }

      @media screen and (max-height: 800px) {
        height: calc(69vh - 25px);
      }

      @media screen and (max-height: 720px) {
        height: calc(67vh - 25px);
      }

      @media screen and (max-height: 680px) {
        height: calc(64vh - 44px);
      }
    }
  }
}

.moreDepartmentTable {
  padding-bottom: 1rem;
  overflow: auto;

  table {
    table-layout: auto !important;
  }

  .ant-table {
    border: 1px solid #d1d3d3;
  }

  .ant-table-container {
    .ant-table-thead {
      tr {
        td {
          &:first-child {
            background-color: @white;
            border-bottom: 1px solid #d1d3d3;
          }

          padding: 0 0;
          min-width: 295px;
          max-width: 295px;

          @media screen and (max-width: 991px) {
            min-width: 140px;
            max-width: 140px;
          }

          &.ant-table-cell-scrollbar {
            max-width: 0;
            min-width: 0;
            width: 0;
          }
        }

        th {
          min-width: 298px;
          max-width: 298px;

          &:nth-child(2){
            min-width: 348px;
            max-width: 348px;
          }
          &:nth-child(3){
            min-width: 352px;
            max-width: 352px;
          }

          &::before {
            top: 57%;
            // height: 7.6em !important;
            height: 100% !important;
          }

          @media screen and (max-width: 991px) {
            min-width: 140px;
            max-width: 140px;
          }
        }
      }
    }

    .ant-table-body {
      height: 100%;

      tr {
        td {
          padding-left: 16px !important;
          padding-right: 16px !important;
          border-top: 1px solid #d1d3d3;
          border-right: none;

          &:nth-child(1) {
            border: none;
            box-shadow: 8px 0 10px 0 rgba(206, 206, 206, 0.25);
            border: none;
            border-right: 1px solid #d1d3d3;
            position: relative;

            &::after {
              position: absolute;
              background: #d1d3d3;
              height: 1px;
              width: 100%;
              content: "";
              left: -100%;
              bottom: 0;
            }
          }
    
          &:nth-child(2) {
            border-right: 1px solid #d1d3d3;
          }
          &:nth-child(3) {
            border-right: 1px solid #d1d3d3;
          }

          &.ant-table-cell {
            height: 44px;
            min-width: 295px;
            max-width: inherit;
            word-wrap: break-word;
            word-break: break-all;

            // width: 295px;
            &.ant-table-cell-fix-left {
              min-width: 295px;
              max-width: 295px;
              word-wrap: break-word;
              width: 295px;

              @media screen and (max-width: 991px) {
                min-width: 140px;
                max-width: 140px;
              }
            }

            @media screen and (max-width: 991px) {
              min-width: 140px;
              max-width: 140px;
            }
          }

          &.ant-table-cell {
            border-left: 1px solid #e2e2e2;
            border-right: 0;

            &:nth-child(2){
              width: 352px;
            }
            &:nth-child(3){
              width: 352px;
            }

            .expand-icon {

              &.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover,
              &.ant-btn:not(:disabled):focus-visible {
                outline: 0 !important;
              }
            }
          }
        }
      }

      .titleRow {
        td {
          &.ant-table-cell {
            border-left: 0;
            font-weight: 500 !important;
            font-size: 1.6rem;
            font-family: @primary-font;
            color: #000;
          }
        }
      }
    }

    .ant-table-body {
      overflow-x: hidden;
    }
  }

  .departmentTableTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    color: #353d40;
    font-family: @primary-font;
    font-size: 1.6rem;
    font-weight: 600;

    span {
      word-break: break-all;
      margin-right: 5px;
    }

    .iconCol {
      display: flex;
      flex-direction: column;

      svg {
        width: 18px;
        height: 18px;
      }

      button {
        height: 26px;
        padding: 3px 3px;
        margin: 0 0 5px 0;
        border: none;
      }
    }

    .deparmentDashboardIcon {
      background-color: @secondary-color;
      border: 1px solid transparent;
      height: 26px;
      width: 26px;

      svg {
        path {
          stroke: @white;
          stroke-width: 50;
        }
      }

      &:hover {
        background-color: transparent;
        border: 1px solid @secondary-color;

        svg {
          path {
            stroke: @secondary-color;
          }
        }
      }
    }

    .deparmentDeleteIcon {
      background-color: #fd4c52;
      border: 1px solid transparent;

      svg {
        path {
          stroke: @white;
          stroke-width: 2;
        }
      }

      &:hover {
        background-color: transparent;
        border: 1px solid #fd4c52;

        svg {
          path {
            stroke: #fd4c52;
          }
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    .ant-table-header {
      tr {
        th {
          padding: 12px 10px !important;

          .departmentTableTitle {
            font-size: 1.4rem;
          }
        }
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        font-size: 13px;
        text-align: left !important;
      }
    }
  }
}

// .projectInfo {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;

//   .projectList {
//     .ant-select {
//       width: 190px !important;

//       .ant-select-selector {
//         background-color: @white !important;

//         .ant-select-selection-item {
//           color: #353d40;
//           font-family: @primary-font;
//           font-size: 1.6rem;
//           font-weight: 400;
//         }
//       }

//       &.ant-select-focused {
//         .ant-select-selector {
//           box-shadow: 0 0 0 1px @secondary-color !important;
//           border-color: transparent !important;
//         }
//       }

//       @media screen and(max-width:850px) {
//         width: 130px !important;

//         .ant-select-selection-item {
//           font-size: 1.3rem !important;
//         }
//       }
//     }
//   }

//   .newProjectWrap {
//     display: flex;
//     align-items: center;
//     margin-left: 10px;

//     .projectSearch {
//       .ant-input-group-wrapper {
//         .ant-input-wrapper {
//           .ant-input {
//             background: @white;
//             border-radius: 8px;
//             border: 1px solid transparent;

//             &:focus {
//               border: 1px solid @secondary-color;
//             }
//           }

//           .ant-input-group-addon {
//             position: absolute;
//             left: 0;
//             top: 0;
//             display: none;
//           }
//         }
//       }
//     }
//   }
// }

// new
.projectInfo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  .ant-form-item {
    margin: 0;
}

  .projectList {
    .ant-select {
      width: 190px !important;

      .ant-select-selector {
        background-color: @white !important;

        .ant-select-selection-item {
          color: #353d40;
          font-family: @primary-font;
          font-size: 1.6rem;
          font-weight: 400;

          @media screen and(max-width:1200px) {
            font-size: 1.4rem;
          }
        }
      }

      &.ant-select-focused {
        .ant-select-selector {
          box-shadow: 0 0 0 1px @secondary-color !important;
          border-color: transparent !important;
        }
      }

      @media screen and(max-width:1200px) {
        width: 130px !important;
      }
    }
  }

  .newProjectWrap {
    display: flex;
    align-items: center;
    // margin-left: 10px;

    .projectSearch {
      .ant-input-group-wrapper {
        .ant-input-wrapper {
          .ant-input {
            background: @white;
            border-radius: 8px;
            border: 1px solid transparent;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
            height: 35px;
            width: 200px;

            &:focus {
              border: 1px solid @secondary-color;
            }

            @media screen and(max-width:1200px) {
              width: 250px;
            }

            @media screen and(max-width:1024px) {
              width: 200px;
            }
          }

          .ant-input-group-addon {
            position: absolute;
            left: 0;
            top: 0;
            display: none;
          }
        }
      }

      @media screen and (max-width:800px) {
        .ant-input-group-wrapper {
          .ant-input-wrapper {
            .ant-input {
              width: 120px !important;
            }
          }
        }
      }
    }

    button {
      margin: 0 10px;

      span {
        @media screen and(max-width:1200px) {
          font-size: 1.2rem;
        }
      }
    }
  }

  // refresh Icon

  .refreshIcon {
    button {
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
      height: 35px;
      border: none;
      margin: 0;

      svg {
        display: flex;
        align-items: center;
        height: 20px;
        width: 20px;

        g {
          path {
            fill: @secondary-color;
          }
        }

        @media screen and(max-width:1080px) {
          width: 20px;
          height: 20px;
        }
      }

      @media screen and(max-width:1080px) {
        font-size: 1.3rem;
        min-width: 100% !important;
      }

    }
  }
}

.deparmentTabs {
  margin-top: 1.5rem !important;
  z-index: 99;
  text-align: center;
  width: 100%;

  .headerTab {
    .ant-segmented {
      background-color: transparent;
      padding: 0;

      .ant-segmented-thumb {
        opacity: 0;
      }

      .ant-segmented-item {
        box-shadow: none;
        position: relative;
        padding: 0.6rem 4rem;
        border-radius: 7px 7px 0 0;
        opacity: 0.6;
        background: #e8e8e8;
        margin: 0 10px 0 0;
        border: 1px solid transparent;

        .ant-segmented-item-label {
          span {
            color: #5e6278;
            font-family: @primary-font;
            font-size: 1.3rem;
            font-weight: 500;

            @media screen and(max-width:850px) {
              font-size: 1.2rem;
            }
          }
        }

        &.ant-segmented-item-selected {
          border: @PrimarythinBorder;
          border-bottom-color: #fff;
          border-bottom-width: 3px;
          background-color: @white;
          opacity: 1;

          .ant-segmented-item-label {
            span {
              color: #171a1b;
              font-weight: 600;
            }
          }
        }

        @media screen and(max-width:1850px) {
          padding: 0.6rem 3rem;
        }

        @media screen and(max-width:1680px) {
          padding: 0.6rem 2rem;
        }

        @media screen and(max-width:1490px) {
          margin: 0rem 0.5rem;
        }

        @media screen and(max-width:1380px) {
          padding: 0.6rem 1rem;
        }

        @media screen and(max-width:1260px) {
          padding: 0.6rem 0.5rem;
          margin: 0rem 0.3rem;
        }

        // position: absolute;

        @media screen and(max-width:1160px) {
          padding: 0.6rem 0.3rem;
          margin: 0rem 0.5rem;
        }
      }

      @media screen and (max-width: 1530px) {
        max-width: 900px;
        // overflow: hidden;
        overflow-x: auto;
      }

      @media screen and (max-width: 1300px) {
        max-width: 700px;
      }

      @media screen and (max-width: 1130px) {
        max-width: 500px;
      }

      @media screen and (max-width: 900px) {
        max-width: 400px;
      }

      @media screen and (max-width: 790px) {
        max-width: 330px;
      }
    }

    .ant-segmented {
      .ant-segmented-item-selected {
        border-bottom-color: #fff !important;
        border-bottom-width: 0px !important;
      }
    }
  }

  .tabContent {
    position: relative; // position: absolute;
    top: -4px;
  }

  .headerTab {
    button {
      padding: 0 0;
      height: 23px;
      width: 23px;
      border-radius: 50%;
      background-color: @primary-color;

      @media screen and(max-width:1530px) {
        bottom: 20px;
      }
    }

    .leftArrow,
    .rightArrow {
      margin: 0 1.5rem 0 0;

      svg {
        margin-top: 3px;

        path {
          fill: rgb(255, 255, 255);
        }

        @media screen and(max-width:1150px) {
          height: 16px;
          width: 10px;
        }
      }

      &:hover {
        background-color: transparent;
        border-color: #000;

        svg {
          path {
            fill: rgb(0, 0, 0);
          }
        }
      }
    }

    .rightArrow {
      margin: 0 0 0 1.5rem;

      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.ant-table-expanded-row-fixed {
  background-color: @white;
  padding: 0 0;
  margin: 0 0;

  .ant-table {
    background-color: @white;
  }
}

//  project dashboard pagination
.verticalPagination {
  position: absolute;
  z-index: 99;
  right: 0;
  background: #e0e2e5;
  width: 50px;
  padding: 0 0.7rem;
  border-radius: 10px;
  border: 3px solid @white;

  .ant-pagination {
    display: block;
    li {
      margin: 10px 0;

      button {
        .anticon {
          svg {
            stroke-width: 10;

            path {
              fill: #3e97ff;
            }
          }
        }
      }
    }

    .ant-pagination-item {
      a {
        color: rgba(0, 0, 0, 0.88);
        font-weight: 500;
        color: #463d3d;
        font-family: @primary-font;
      }
    }

    .ant-pagination-item-active {
      font-weight: 500;
    }
  }
}

// cost section
.costSection {
  display: flex;

  @media screen and (max-width: 1470px) {
    .ant-col-5 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .ant-col-4 {
      flex: 0 0 24%;
      max-width: 24%;
    }
  }

  @media screen and (max-width: 1024px) {
    .ant-col-5 {
      flex: 0 0 48%;
      max-width: 48%;
    }

    .ant-col-4 {
      flex: 0 0 48%;
      max-width: 48%;
    }
  }

  // remove col

  .costRemoveIcon {
    display: flex;
    align-self: center;
    // position: absolute;
    // top: 110px;
    // right: 0;
  }
}