.main__page__appsidebar {
  height: 100vh;
  background-color: @layout-sidebar-background !important;
  background-repeat: repeat;
  background-size: contain;
  background-position: 0 0;

  .ant-menu {
    font-size: 1.3rem;
    font-weight: 500;
    height: calc(100vh - (@layout-header-height + 60px));
    overflow-y: auto;

    &.ant-menu-dark {
      color: @white;
      background: transparent;
      overflow-x: hidden;

      &:not(.ant-menu-horizontal)>.ant-menu-item-selected {
        border-radius: 40px 0 0 40px;

        .anticon+span {
          color: @white;
        }

        svg {
          opacity: 1;

          g {
            path {
              fill: @secondary-color !important;
              stroke: @secondary-color !important;
            }
          }
        }
      }

      .ant-menu-item {
        background-color: transparent !important;
        border-radius: 40px 0 0 40px;

        .ant-menu-title-content {
          padding: 0 15px 0 20px;
          font-family: @primary-font;
          font-size: 1.4rem;
          font-weight: 500;
        }

        &:before {
          content: "";
          display: block;
          position: absolute;
          top: -10px;
          right: 0px;
          width: 15px;
          height: 10px;
          background: white;
          z-index: 9;
          opacity: 0;
          background: radial-gradient(ellipse at 0% -25%,
              transparent 0,
              transparent 70%,
              @layout-body-background 70%,
              @layout-body-background 100%);
          transition: border-color 0.3s, opacity 0.3s,
            padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: auto;
          right: -3px;
          bottom: -12px;
          width: 15px;
          height: 10px;
          background: white;
          opacity: 1;
          z-index: 9;
          opacity: 0;
          transform: rotate(-90deg);
          background: radial-gradient(ellipse at 0% -25%,
              transparent 0,
              transparent 70%,
              @layout-body-background 70%,
              @layout-body-background 100%);
          transition: border-color 0.3s, opacity 0.3s,
            padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }

      &:not(.ant-menu-horizontal) .ant-menu-item-active {
        background-color: @layout-body-background !important;
        border-radius: 40px 0 0 40px;

        &:before {
          opacity: 1;
        }

        &:after {
          opacity: 1;
        }
      }

      .ant-menu-item,
      .ant-menu-item-group-title,
      .ant-menu-item>a,
      .ant-menu-item>span>a {
        color: @white;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .ant-menu-sub {
      height: auto;
      overflow-y: unset;
    }

    .ant-menu-item {
      // padding: 5px 0 5px 15px !important;
      border-radius: 0;
      overflow: visible;
      width: 100%;

      &.ant-menu-item-active {
        background-color: @layout-body-background;

        .ant-menu-item-icon {
          opacity: 1;

          g {
            path {
              fill: @secondary-color;
              stroke: @secondary-color;
            }
          }

          path {
            fill: @secondary-color;
            stroke: @secondary-color;
          }
        }

        .ant-menu-item-active,
        &.ant-menu-item-selected {
          img {
            filter: invert(49%) sepia(38%) saturate(2137%) hue-rotate(193deg) brightness(101%) contrast(104%);
          }
        }

      }

      .ant-menu-item-icon {
        max-width: 20px;
        opacity: 1;
        transition: all 0.5s ease;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .userIcon {
        path {
          fill: transparent !important;
        }

      }

      .masterIcon {
        width: 20px;
        height: 20px;
      }

      &:hover,
      .ant-menu-item-selected {
        .ant-menu-item-icon {
          opacity: 1;
        }
      }

      svg {
        width: 20px;
        height: 20px;

        path {
          fill: @white ;
          stroke: @white ;
        }
      }
    }

    &.ant-menu-root {
      .ant-menu-submenu {
        border-radius: 0;
        font-size: 14px;
        font-weight: 400;

        .ant-menu-item-icon {
          max-width: 20px;
          opacity: 1;

          g {
            path {
              fill: @white;
            }
          }
        }

        .ant-menu-submenu-title {
          border-radius: 40px 0 0 40px;
          transition: all 0.1s ease;
          position: relative;

          >.ant-menu-title-content {
            padding-right: 10px;
            padding-left: 18px;
            font-family: @primary-font;
            font-size: 1.4rem;
            font-weight: 500;
          }

          svg {
            transition: all 0.5s ease;
          }
        }

        &.ant-menu-submenu-open {
          >.ant-menu-submenu-title {
            >svg {
              transform: rotate(90deg);
            }
          }
        }

        .ant-menu-sub {
          background: transparent;
          padding: 0 20px 10px;
          padding-left: 32px;
          color: @white;

          .ant-menu-item {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            padding-left: 0 !important;
            background-color: transparent;
            border-radius: 0;
            border-left: 1px solid @white;

            &:before {
              display: none;
            }
            img{
              display: none;
            }
          }

          .ant-menu-submenu {
            border-left: 1px solid @white;
          }

          .ant-menu-item-selected {
            background-color: transparent;
            color: @secondary-color;

            .ant-menu-title-content {
              color: @secondary-color !important;
            }
          }

          .ant-menu-item-active {
            color: @white;
            background-color: rgb(255 255 255 / 10%);
          }

          .ant-menu-item-active:after {
            display: none;
          }
        }
      }
    }
  }

  &.ant-layout-sider-collapsed {
    .logo__wrapper {
      span {
        display: none;
      }
    }

    //   .ant-menu .ant-menu-item
    //   // .ant-menu .ant-menu-submenu .ant-menu-submenu-title 
    // {
    //     // padding: 3px 23px !important;
    //   }

    .ant-menu-dark .ant-menu-item:hover {
      color: @text_dark_black;
    }


  }

  .logo__wrapper {
    padding: 5px 20px;
    background-color: @white;
    border-radius: 0 0 165px 165px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(-1.04px 4.891px 3.5px rgba(0, 0, 0, 0.14));

    img {
      max-height: 100%;
      max-width: 125px;
      border-radius: 50%;
    }

    span {
      color: #fff;
    }
  }

}

.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-inline>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 55px;
  line-height: 55px;
}

.ant-menu-submenu {
  .ant-menu-sub {
    .ant-menu-item {
      background-color: @primary-color !important;

      .ant-menu-title-content {
        color: @white !important;
        font-family: @primary-font;
        font-size: 1.3rem !important;
      }
    }
  }
}

.ant-menu-submenu-popup {

  .ant-menu-vertical.ant-menu-sub,
  .ant-menu-vertical-left.ant-menu-sub,
  .ant-menu-vertical-right.ant-menu-sub {
    max-height: initial;

  }
}

.ag-header-row-column-group {
  background-color: @primary-color;
  color: @white;

  .ag-header-group-cell-label {
    color: @white;
    justify-content: center;
  }
}

.ant-layout-sider .ant-menu-submenu-popup {
  .ant-menu-item {
    color: @primary-color;

    &.ant-menu-item-active {
      color: @white;
      // padding: 3px 23px !important;
    }
  }
}

.ant-menu-submenu-popup {
  .ant-menu-item {
    color: @primary-color;

  }
}

.ant-layout-sider {
  .ant-menu-dark {

    .ant-menu-item:hover,
    .ant-menu-item-active,
    .ant-menu-submenu-active,
    .ant-menu-submenu-open,
    .ant-menu-submenu-selected,
    .ant-menu-submenu-title:hover {
      color: @white;
    }
  }
}

.ant-menu-dark {

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu-submenu-active,
  .ant-menu-submenu-open,
  .ant-menu-submenu-selected,
  .ant-menu-submenu-title:hover {
    color: @primary-color;
  }
}

.ant-layout-sider-collapsed {
  .ant-layout-sider-children {
    .ant-layout-footer {
      display: none;
    }
  }
}


.ant-menu-item-active {
  .linkClass {
    color: @primary-color !important;
    font-weight: 500 !important;
    font-family: @primary-font;
  }
}

.ant-menu-submenu-open {
  &.ant-menu-submenu-selected {
    .ant-menu-item-selected {
      .linkClass {
        color: @secondary-color !important;
      }
    }
  }

  .ant-menu-sub {
    .ant-menu-item {
      .ant-menu-item-icon {
        display: none;
      }
    }
  }
}

.ant-menu-submenu {
  .ant-menu-item-icon {
    max-width: 33px !important;

  }
}

.ant-menu-submenu-title {
  padding: 0 10px !important;
}

.ant-menu-item-active {
  img {
    &.ant-menu-item-icon {
      filter: invert(49%) sepia(38%) saturate(2137%) hue-rotate(193deg) brightness(101%) contrast(104%);
    }
  }

}

.ant-menu-dark .ant-menu-item:not(.ant-menu-item-disabled):focus-visible {
  outline: none !important;
}

.ant-menu-submenu-popup {

  height: 350px !important;
  overflow-y: scroll !important;

}

.ant-menu-submenu-title {
  img {
    margin-left: 8px !important;
  }
}