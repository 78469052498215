.filterPopover {
    width: 320px;
    max-width: 320px;

    .ant-popover-content {
        .filterPopoverRow {
            .filterForm {
                padding: 0 10px 15px 10px;

                .advanceFilter {
                    .advanceFilterTitle {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        h3 {
                            .semiMediumFonts();
                        }

                        button {
                            margin-right: 10px;
                            padding: 5px 8px;
                            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
                            background-color: #fff;
                            border: 1px solid transparent;

                            svg {
                                height: 15px;
                                width: 15px;

                            }

                            &.close {
                                svg {
                                    path {
                                        fill: red;
                                    }
                                }
                            }
                        }
                    }

                    .ant-select-clear {
                        display: none;
                    }
                }

                .btnRow {
                    margin-top: 30px;

                    button {
                        margin-right: 10px;
                        padding: 5px 8px;
                        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
                        background-color: @white;
                        border: 1px solid transparent;

                        svg {
                            height: 15px;
                            width: 15px;
                        }
                    }

                    .applied {
                        svg {
                            fill: rgb(114, 187, 4);

                        }
                    }

                    .reset {
                        svg {
                            height: 21px;
                            width: 21px;

                            path {
                                fill: @secondary-color;
                            }
                        }
                    }

                    .close {
                        svg {
                            path {
                                fill: rgba(255, 0, 0, 0.918);
                            }
                        }
                    }

                }

            }
        }
    }
}

.mainOption {
    display: flex;
    flex-direction: column;
    width: 32%;
    position: relative;
    padding-bottom: 24px;
    height: 100px;
}

.optionBox {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    margin: 0 0 15px 15px;


    label {
        .regulerFonts();
    }

    .ant-form-item {
        margin-bottom: 0;
        width: 93%;
    }

    .optionBtn {
        width: 6%;
        right: 20px;
        top: 47px;
        position: absolute;

        @media screen and (max-width:1024px) {
            width: 18%;
        }
    }

    button {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        padding: 0 0;
        margin: 0 3px 0 5px;

        svg {
            height: 16px;
            width: 16px;
            margin-top: 5px;

            path {
                fill: black;
                stroke-width: 2px;
            }
        }

        &.rightTic {
            background-color: green;
        }

        &.cancelTic {
            background-color: #e33232;

            svg {
                path {
                    stroke-width: 5px;
                }
            }
        }

    }

    .ant-input {
        padding: 0 80px 0 12px;
    }

    @media screen and (max-width:1024px) {
        width: 40%;
    }
}

.inputRow {
    align-items: center;
}


// .tabElementWrapper{
//     .tabContent{
//         top: 0;
//     }
// }
.questionTabs {
    top: 0;
    .ant-segmented {
        max-width: 1200px;
        //overflow-x: auto;
        overflow: hidden;

        @media screen and (max-width:1700px) {
            max-width: 800px;
            overflow-x: scroll;
        }

        @media screen and (max-width:1200px) {
            max-width: 600px;
        }

        @media screen and (max-width:1100px) {
            max-width: 500px;
        }

        @media screen and (max-width:900px) {
            max-width: 400px;
        }
        @media screen and (max-width: 790px) {
            max-width: 330px;
          }
        .ant-segmented-item-label {
            font-family: @primary-font;
            font-weight: 400;
        }
    }

    @media screen and (max-width: 1920px) {
        button {
            bottom: 12px;
        }
    }

}


.questionWrapper {
    // height: calc(100% + 10px);
    height: 100%;

    @media screen and (max-height:1380px) {
        .tabContent {
            height: calc(100% - 160px) !important;
        }
    }


    .actionRow {
        position: absolute;
        bottom: 0;
        width: 100%;

        @media screen and (max-height:700px) {
            bottom: 8px;
        }
    }

    @media screen and (max-height:900px) {
        height: calc(100% - 0px);
    }
}

.checkboxWrapper {
    display: flex;
    align-items: center;

    .ant-checkbox {
        margin-top: 0 !important;
    }

    span {
        .regulerFonts();
    }
}

.optionForm {
    display: flex;
    align-items: center;
}

.filterBtn {
    margin-right: 2rem;
    height: 35px;
    padding: 1.1rem 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @white;
    border: none;
    border: 1px solid transparent;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

    svg {
        height: 18px;
        width: 18px;

        path {
            stroke: @secondary-color;

        }
    }

    &:hover {
        background-color: transparent;
        border: 1px solid @secondary-color;
    }
}


//  question Sequence 

.sequenceTable {
    table {
        border-spacing: 0 10px;
    }

    .ant-table {
        .ant-table-thead {
            tr {
                td {
                    border: none;
                    box-shadow: none;
                    background-color: transparent;

                    &::before {
                        display: none;
                    }
                }

            }
        }

        .ant-table-tbody {
            border: none;

            tr {
                td {
                    &:first-child {
                        text-align: center;
                        background: #c8c8c8;
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                        border: 1px solid #b3b3b3;

                        svg {
                            fill: @secondary-color;
                            height: 18px;
                            width: 18px;
                        }
                    }

                    &:nth-child(2) {
                        background-color: #f2f2f2;
                        font-weight: 500 !important;
                    }
                }
            }

            .anticon {
                cursor: pointer !important;
            }
        }
    }
}

.mainWrapper {
    // position: absolute;
    // top: 50px;
    width: 100%;
    height: calc(100% - 10px);

    @media screen and (max-height:768px) {
        top: 45px;
    }

    @media screen and (max-height:600px) {
        top: 42px;
    }
}

.questionListWrap {
    height: calc(100% - 117px);
    border-radius: 10px;
    border: @PrimarythinBorder;
    background-color: @white;
    position: absolute;
    width: 100%;
    top: 37px;

    .listPage {
        display: flex;
        justify-content: flex-end;
        padding: 10px 20px 0 0;
        background-color: @white;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        .ant-select{
            .ant-select-selector{
                height: 35px;
                .ant-select-selection-item{
                    line-height: 35px;
                }
            }
        }
    }

    .questionList {
        height: calc(100% - 52px);
        @media screen and (max-width:700px) {
            height: calc(100% - 55px);
        }
    }


    .steelBtn {
        position: absolute;
        z-index: 9;
        right: 170px;
        top: 25px;

        span {
            margin: 0 0.5rem;
        }
    }

    .btnWrap {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 1rem;

        button {
            margin-right: 2rem;
        }

        >* {
            margin-left: 0;
        }


        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
        .ant-select:not(.ant-select-multiple) .ant-select-selector,
        .ant-select-selector {
            border: 0;
            background-color: @white;
            box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
            bottom: 3px;
            height: 40px;
        }

        .iconBtn {
            width: 42px;
            height: 42px;
            background-color: @white;
            border-radius: 8px;
            cursor: pointer;
            box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
            border: 0;
            padding: 6px;
            font-size: 18px;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            svg {
                transition: all 0.5s ease;
            }

            &:hover svg {
                fill: @primary-color;
            }
        }

        .ant-select {
            margin-right: 20px;
        }
    }

    .ag-body {
        height: 39vh;
    }

    @media screen and (max-height:700px) {
        height: calc(100% - 135px);
    }
}

.addDrawer {
    .btnBox {
        top: 0;
        right: 18px;
        bottom: 0;
        margin: 14% 0;

    }
}

.editDrawer {
    .btnBox {
        top: 0;
        right: 18px;
        bottom: 0;
        margin: 14% 0;
    }
}

.addQuestionPage {
    height: calc(100% - 34px);
    width: 100%;
    .detailCol {
        margin-bottom: 30px;
    }

    .detailPage {
        background-color: @white;
        // padding: 2rem;
        border-radius: 10px;
        height: 100%;
        width: 100%;
        position: relative;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

        .ant-row {
            padding: 0 10px;
        }

        .ant-select {
            &.ant-select-focused {
                border-radius: 4px;
            }
        }
    }


    .projectTitle {
        background-color: @secondary-color;
        padding: 14px 25px;
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        margin-bottom: 15px;

        h2 {
            border-radius: 40px;
            margin: 0 5px;
            font-family: @primary-font;
            font-size: 17px;
            color: @white;

        }

    }

    .bottomBtnRow {
        width: 100%;
        text-align: center;
    }
    #UserAdd{
        height: 100%;
        position: relative;
        width: 100%;
        .ant-row{
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }
    @media screen and (max-width:991px) {
        .ant-col-12{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

}