 @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500;600&family=Poppins:wght@200;400;500;600;700;800&display=swap');

@primary-color: #2f373a;
@secondary-color: #3e97ff;
@card-bg-color:#353D40;
@black: #000;
@cancel-color:rgba(237,30,43,  0.76);
@font-color: rgba(0, 0, 0, 0.9);
@white: #fff;
@input-color:#f2f2f2;
@dropdown-color:#c8c8c8;
@text_dark_black: #2e2d2c;
@text_light_black: #23211e;
@action_btn_color: #696b71;
@success-color: #52c41a;
@warning-color: #faad14;
@error-color: #f5222d;
@label-color: #181c32;
@input-bg: #f3f5f7;
@primary-font: "Poppins", sans-serif;
@secondary-font :'Oswald', sans-serif;
@border-color:#e0e0e0;
@gutter: 24px;
@text-color: #494949;

// Main Header Variables
@layout-body-background: #f7f7f7;
@layout-header-background: @white;
@layout-header-color: @text-color;
@layout-sidebar-background: @primary-color;
@layout-header-height: 125px;

// Action Button Color variables
@orange-btn: #ffa500;
@red-btn: #f10000;
@blue-btn: #0f01ff;

// Main Header Logo Variables
@logo-height: 42px;

// Section Variables
@section-padding: 50px;
@screen-md: 768px;
@screen-lg: 992px;
@screen-xl: 1200px;
@screen-xxl: 1600px;

// border 
@PrimarythinBorder: 1px solid @border-color;

.themeColorOpacity(@rgbColor, @opacity) {
  background-color: rgba(@rgbColor, @opacity);
}

.transition(@time,@effect) {
	transition: all @time @effect;
}

.regulerFonts{
  font-weight: 400;
  font-family: @primary-font;
}
.semiMediumFonts{
  font-weight: 500;
  font-family: @primary-font;
}
.mediumFonts{
  font-weight: 600;
  font-family: @primary-font;
}
.boldFonts{
  font-weight: 700;
  font-family: @primary-font;
}