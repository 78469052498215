.headerTabs {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  overflow-x: auto;
  width: 100%;

  .ant-segmented {
    background-color: transparent;
    padding: 0px;

    .ant-segmented-thumb {
      opacity: 0;
    }

    .ant-segmented-item {
      box-shadow: none;
      position: relative;
      padding: 0.6rem 4rem;
      border-radius: 7px 7px 0 0;
      opacity: 0.6;
      background: #e8e8e8;
      margin: 0px 10px 0 0;
      border: 1px solid transparent;

      .ant-segmented-item-label {
        span {
          color: #5e6278;
          font-family: @primary-font;
          font-size: 1.3rem;
          font-weight: 500;

          @media screen and(max-width:850px) {
            font-size: 1.2rem;
          }
        }
      }

      &.ant-segmented-item-selected {
        border: @PrimarythinBorder;
        border-bottom-color: #fff;
        border-bottom-width: 3px;
        background-color: @white;
        opacity: 1;

        .ant-segmented-item-label {
          span {
            color: #171a1b;
            font-weight: 600;
          }
        }
      }

      @media screen and(max-width:1850px) {
        padding: 0.3rem 3rem;
      }

      @media screen and(max-width:1680px) {
        padding: 0.3rem 2rem;
      }

      @media screen and(max-width:1490px) {
        margin: 0rem 0.5rem;
      }

      @media screen and(max-width:1380px) {
        padding: 0.3rem 1rem;
      }

      @media screen and(max-width:1260px) {
        padding: 0.3rem 0.5rem;
        margin: 0rem 0.3rem;
      }

      @media screen and(max-width:1160px) {
        padding: 0.3rem;
        margin: 0rem 0.5rem;
      }
    }

    @media screen and (max-width: 1530px) {
      max-width: 900px;
      // overflow: hidden;
      overflow-x: auto;
    }

    @media screen and (max-width: 1300px) {
      max-width: 700px;
    }

    @media screen and (max-width: 1130px) {
      max-width: 500px;
    }

    @media screen and (max-width: 900px) {
      max-width: 400px;
    }

    @media screen and (max-width: 790px) {
      max-width: 330px;
    }
  }

  button {
    padding: 0px 0px;
    height: 23px;
    width: 23px;
    border-radius: 50%;
    bottom: 0;
    background-color: @primary-color;

    @media screen and (max-width: 1530px) {
      bottom: 15px;
    }
  }

  .leftArrow,
  .rightArrow {
    margin: 0 1.5rem 0 0;

    svg {
      margin-top: 3px;

      path {
        fill: rgb(255, 255, 255);
      }

      @media screen and(max-width:1150px) {
        height: 16px;
        width: 10px;
      }
    }
    &:hover {
      background-color: transparent;
      border-color: #000;

      svg {
        path {
          fill: rgb(0, 0, 0);
        }
      }
    }
  }

  .rightArrow {
    margin: 0 0 0 1.5rem;

    svg {
      transform: rotate(180deg);
    }
  }
}

.tabElementWrapper {
  height: calc(100% + 10px);
  position: relative;

  .borderActionRow {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  @media screen and (max-width: 890px) {
    height: calc(100% + 30px);
  }
}

.secondaryBtn {
  .btnIcon {
    margin-left: 1rem;
  }
}

.ag-grid-wrapper {
  box-shadow: none !important;
}

.rawListWrapper {
  height: 100%;

  .rawList {
    position: absolute;
    width: 100%;
    top: 38px;
    height: calc(100% - 133px);
    background-color: #fff;
    padding: 0 0;
    border-radius: 10px;
    border: @PrimarythinBorder;

    .btnWrap {
      // background: white;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px 20px 5px 10px;

      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
      .ant-select:not(.ant-select-multiple) .ant-select-selector,
      .ant-select-selector {
        border: 0;
        background-color: @white;
        box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
        bottom: 3px;
        height: 40px;
      }

      .iconBtn {
        width: 42px;
        height: 42px;
        background-color: @white;
        border-radius: 8px;
        cursor: pointer;
        box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
        border: 0;
        padding: 6px;
        font-size: 18px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        svg {
          transition: all 0.5s ease;
        }

        &:hover svg {
          fill: @primary-color;
        }
      }

      .ant-select {
        margin-right: 20px;
        margin-top: 5px;
      }
    }

    .ag-body {
      height: 39vh;
    }

    .ag-grid-wrapper {
      height: calc(100% - 57px);
    }

    // @media screen and (max-width:1150px) {
    //   height: calc(100% + 15px);
    // }
  }
}

// }

// steel form
form {
  .addSteelForm {
    width: 100%;
    background-color: @white;
    padding: 3rem;
    height: auto;
    overflow: auto;

    .ant-form-item {
      height: 90px;

      .ant-form-item-control-input {
        min-height: auto;
      }
    }

    .uploadRow {
      display: flex;
      flex-direction: column;

      label {
        font-size: 1.4rem;
        color: @font-color;
        font-weight: 400;
        font-family: @primary-font;
        white-space: inherit;
        height: inherit;
        align-items: flex-start;
        min-height: 27px;

        @media screen and (max-width: 1600px) {
          font-size: 1.3rem;
        }
      }

      .ant-upload-wrapper {
        .ant-upload-select {
          background-color: #f2f2f2;
          border-radius: 4px;
        }
      }
    }

    @media screen and(max-width:991px) {
      .ant-col-12 {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
}

.testTable {
  margin: 1.5rem 0;

  h2 {
    margin-bottom: 1rem;
    color: #353d40;
    font-family: @primary-font;
    font-size: 1.6rem;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border: @PrimarythinBorder;
  }

  td,
  th {
    padding: 8px;
    text-align: left;
  }

  @media screen and(max-width:1551px) {
    .table-wrapper {
      overflow-x: scroll;
    }
  }

  table {
    border-radius: 7px;
    width: 100%;
    overflow-x: auto;
    height: auto;
    background: #fff;

    thead {
      position: sticky;
      top: -20px;
      z-index: 99;

      tr {
        td {
          background-color: @white;
          color: #353d40;
          font-family: @primary-font;
          font-size: 15px;
          font-weight: 500;
          padding: 1.2rem;
          border-right: @PrimarythinBorder;
          border-bottom: @PrimarythinBorder;
          z-index: 99;
          position: sticky;
          text-align: center;

          &:last-child {
            border-right: none;
          }
        }

        .extracol {
          border: none;
        }

        .borderRight {
          border-right: none;
        }
      }

      .subTitle {
        text-align: center;

        td {
          font-size: 1.3rem;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        border-bottom: none;
        color: #353d40;
        font-family: @primary-font;
        font-size: 14px;
        font-weight: 400;
        padding: 1.2rem;
        border-right: @PrimarythinBorder;
        text-align: center;
        vertical-align: initial;
        padding-top: 18px;

        .ant-form-item {
          margin-bottom: 0px;

          .ant-form-item-control-input-content {
            .ant-input-number,
            .ant-input {
              background-color: #f2f2f2;
              border: 1px solid #f2f2f2;
              height: 35px;
              width: 90px;
              border-radius: 7px;
            }

            .ant-input-number-input,
            .ant-input {
              height: 33px;
              background-color: #f2f2f2;
            }
          }
        }

        &:last-child {
          border-right: none;
        }

        .ant-form-item {
          height: 105px;
        }

        height: 36px;
      }

      .borderRight {
        border-right: none;
      }
    }
  }

  .addIcon {
    padding: 0;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin-left: 10px;
    background-color: @secondary-color;
    border: 1px solid transparent;

    svg {
      height: 14px;
      width: 23px;

      path {
        fill: white;
      }
    }

    &:hover {
      background-color: transparent;
      border: 1px solid @primary-color;

      svg {
        path {
          fill: @primary-color !important;
        }
      }
    }
  }

  .diaCol {
    height: auto;

    .diaAlign {
      display: flex;
      align-items: center;

      button {
        padding: 0;
        border: none;
        box-shadow: none;
        margin-right: 10px;
        background-color: rgb(233, 136, 136);
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid transparent;

        svg {
          path {
            fill: white;
          }
        }

        &:hover {
          background-color: transparent;
          border: 1px solid rgb(233, 136, 136) !important;

          svg {
            path {
              fill: rgb(233, 136, 136);
            }
          }
        }
      }
    }
  }
}

.tableWrapper {
  overflow-x: auto;
  white-space: nowrap;
}

// steel form
form {
  .addSteelForm {
    box-shadow: 0 0 0 1px @border-color;
    border-radius: 7px;

    .pileNumber {
      color: #5e6278;
      font-size: 1.4rem;
      .semiMediumFonts();
    }
  }

  .steelBtn {
    justify-content: flex-end;
    display: flex;

    @media screen and (max-width: 991px) {
      padding: 0 0 30px 0;
    }

    button {
      margin-bottom: 10px;
    }
  }

  .polyBtn {
    position: absolute;
    bottom: 20px;
    right: 50px;
    width: 100%;
    background: transparent;
    margin-bottom: 10px;

    @media screen and (max-width: 991px) {
      bottom: 0px;
    }
  }
}

// report
.listBtnWrap {
  background-color: #fff;
  padding: 0rem 0 0 0;
  border-radius: 10px;
  height: calc(100% - 165px);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

  .listBtn {
    display: flex;
    justify-content: flex-end;
    margin-right: 3rem;
    padding: 20px 20px 0 20px;

    .btnIcon {
      margin-left: 1rem;
    }
  }
}

.commlisting {
  height: calc(100% - 100px);
  border: @PrimarythinBorder;
  border-radius: 8px;

  .ag-theme-alpine {
    &.ag-grid-wrapper {
      box-shadow: none !important;
      height: 100%;
    }
  }

  .dataNotFoundBg {
    border-radius: 20px;
    border: 1px dashed #e2e2e2;
    background: #f8f8f8;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 4rem;

    span {
      text-align: center;
      display: flex;
      align-items: center;
      padding: 20px 0px;
      justify-content: center;
    }

    p {
      text-align: center;
      color: #5e6278;
      font-family: @primary-font;
      font-size: 17px;
      font-weight: 500;
    }
  }

  .listBtn {
    padding: 20px 20px 0 0;
  }
}

.listing {
  background-color: @white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 2rem 10px;
  height: 100%;

  .listBtn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4rem;

    .btnIcon {
      margin-left: 1rem;
    }
  }

  .ag-theme-alpine {
    &.ag-grid-wrapper {
      box-shadow: none !important;
    }
  }

  .dataNotFoundBg {
    border-radius: 20px;
    border: 1px dashed #e2e2e2;
    background: #f8f8f8;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 4rem;

    span {
      text-align: center;
      display: flex;
      align-items: center;
      padding: 20px 0;
      justify-content: center;
    }

    p {
      text-align: center;
      color: #5e6278;
      font-family: @primary-font;
      font-size: 17px;
      font-weight: 500;
    }
  }
}

.addReportForm {
  box-shadow: 0 0 0 1px @border-color;
  border-radius: 7px;
  width: 100%;
  background-color: @white;
  padding: 3rem;
  height: auto;
  overflow: auto;

  .select {
    margin-bottom: 0;

    .ant-select {
      background-color: #f2f2f2;
      border-radius: 4px;
      height: 45px;

      .ant-select-selector {
        background-color: transparent;
        height: 42px;
      }
    }
  }

  .ant-form-item {
    height: 90px;
  }

  .uploadRow {
    display: flex;
    flex-direction: column;

    label {
      font-size: 1.4rem;
      color: @font-color;
      font-weight: 400;
      font-family: @primary-font;
      white-space: inherit;
      height: inherit;
      align-items: flex-start;
      min-height: 27px;

      @media screen and (max-width: 1600px) {
        font-size: 1.3rem;
      }
    }
  }

  .concreteForm {
    .uploadRow {
      display: flex;
      flex-direction: column;

      label {
        font-size: 1.4rem;
        color: @font-color;
        font-weight: 400;
        font-family: @primary-font;
        white-space: inherit;
        height: inherit;
        align-items: flex-start;
        min-height: 27px;

        @media screen and (max-width: 1600px) {
          font-size: 1.3rem;
        }
      }
    }
  }

  .mixDesignForm {
    .ant-form-item {
      &.select {
        margin-bottom: 25px;
      }
    }

    .ant-form-item {
      height: 92px;
    }

    .uploadRow {
      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          .ant-upload-wrapper {
            .ant-upload-list {
              margin-top: 7rem;
              display: flex;
              flex-wrap: wrap;
            }
          }
        }
      }
    }

    .ant-form-item-explain-error {
      margin-top: 6px;
    }

    @media screen and(max-width:991px) {
      .ant-col-12 {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }

  .ReportBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  @media screen and(max-width:1250px) {
    .ant-col-8 {
      max-width: 50%;
      flex: 0 0 50%;
    }
  }

  @media screen and(max-width:991px) {
    .ant-col-8 {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }

  .ant-form-item-explain-error {
    margin-top: 6px;
  }

  .textField {
    margin-bottom: 24px !important;

    .ant-select {
      .ant-select-clear {
        right: 12px;
        top: 44%;
        width: 17px;
        height: 17px;
        // color: #3577c5;
        font-size: 17px;
        background: white;
        border-radius: 50%;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .ant-col-12 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.cubeForm {
  padding: 3rem 6rem;

  .btnRow {
    margin-bottom: 3rem;
  }

  @media screen and(max-width:991px) {
    padding: 3rem 1.2rem;
  }
}

.ant-table {
  box-shadow: none !important;

  table {
    thead {
      .tableTitle {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        .iconCol {
          display: flex;
          align-items: center;
          margin-left: -2px;

          button {
            padding: 0 0;
            border: none;
            height: 22px;
            width: 22px;
            margin-right: 5px;

            svg {
              path {
                fill: #a4a4a4;
                stroke-width: 2px;
              }
            }

            &:hover {
              svg {
                path {
                  fill: @secondary-color;
                }
              }
            }
          }

          .mixDelete {
            svg {
              path {
                fill: transparent !important;
                stroke: #a4a4a4;
              }
            }

            &:hover {
              svg {
                path {
                  stroke: #fd4c52;
                }
              }
            }
          }
        }

        span {
          font-family: @primary-font;
          font-size: 1.4rem;
          font-weight: 500;
          margin-bottom: 0.5rem;
          color: #353d40;
        }

        .subTitle {
          margin-bottom: 5px;
        }
      }

      th {
        background-color: @white !important;

        &.ant-table-cell {
          font-family: @primary-font;
          font-size: 1.4rem;
          font-weight: 500 !important;
          margin-bottom: 0rem;
          color: #353d40;

          &::before {
            // height: 5.6em !important;
            height: 100% !important;
            background-color: #e2e2e2 !important;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          &.ant-table-cell {
            font-family: @primary-font;
            font-size: 1.4rem;
            font-weight: 400 !important;
            margin-bottom: 1rem;
            color: #353d40;
            border-bottom: none;
            border-right: 1px solid #e2e2e2;

            &::before {
              // height: 5.6em !important;
              height: 100% !important;
              background-color: #e2e2e2 !important;
            }
          }
        }
      }

      .nameCol {
        width: 10% !important;
      }
    }
  }
}

.approveModal {
  .ant-modal-content {
    border: 2px dashed #5e6278;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .ant-modal-body {
      width: 100%;
      text-align: center;
      padding: 24px 24px 0 24px;

      form {
        .ant-form-item {
          align-items: center;
          display: flex;
          justify-content: center;

          .ant-form-item-label {
            label {
              color: #5e6278;
              font-size: 16px;
            }
          }
        }
      }
    }

    .ant-modal-footer {
      padding: 0 0 24px 0;
    }
  }
}

.ant-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      background-color: @primary-color;
      color: @white;
      font-weight: 500;
      font-family: @primary-font;
    }
  }
}

// polymer Table
.polymerTable {
  width: 100%;

  .headRow {
    border-bottom: 1px solid #e2e2e2;

    h2 {
      color: #353d40;
      font-family: @primary-font;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .headRight {
    border-right: 1px solid #e2e2e2;
  }

  .paddingRow {
    .ant-form-item {
      margin: 3rem 5.2rem 2rem 11rem;
      height: 30px;

      @media screen and(max-width:991px) {
        margin: 3rem 2rem 2rem 2rem;
      }
    }
  }

  .tableLabel {
    color: #353d40;
    font-family: @primary-font;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    h4 {
      margin: 0 3rem;
      text-align: center;

      @media screen and(max-width:991px) {
        font-size: 1.4rem;
        margin: 0 0rem;
      }
    }
  }

  .ant-form-item-explain {
    .ant-form-item-explain-error {
      margin: 4px 0 5px 0;
    }
  }
}

// cement bran dropdown
.cementBrandDropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ant-form-item {
    margin-bottom: 0 !important;
    padding: 10px 0 10px 15px;
    width: 80%;
    height: 120px;
    line-height: 5;
  }

  .disableBtnWrap {
    height: 100px;
    line-height: 5;

    .cementDropdownBtn {
      height: 42px;
      background: @primary-color;
      margin-left: 5px;

      span {
        color: @white;
      }

      &:hover {
        background: @primary-color !important;

        span {
          color: @white !important;
        }
      }
    }

    .cementDropDisableBtn {
      height: 42px;
      background: #d2d2d3;
      margin-left: 5px;
      color: @white;

      span {
        color: @white;
      }

      .ant-btn-icon {
        svg {
          path {
            fill: @white;
          }
        }
      }
    }
  }

  .cementDropdownBtn {
    height: 42px;
    background: @primary-color;
    margin-left: 5px;

    span {
      color: @white;
    }

    &:hover {
      background: @primary-color !important;

      span {
        color: @white !important;
      }
    }
  }

  .cementDropDisableBtn {
    height: 42px;
    background: #999a9d;
    margin-left: 5px;
  }
}

// mix design
.formMainWrapper {
  height: calc(100% - 40px);

  .addReportForm {
    height: calc(100vh - 165px);
  }
}

//form layout
.formLayout {
  height: calc(100vh - 125px);
}

// planning
// .planningContainer {
//   .planningForm {
//     height: 100%;
//     position: relative;

//     // overflow: auto;
//     .addSteelForm {
//       height: calc(85vh - 30px);
//       overflow: auto;
//     }

//   }

//   // .stepBtn {
//   //   position: absolute;
//   //   bottom: 0;
//   //   padding: 20px;
//   //   background: #ffffff;
//   //   width: 100%;
//   //   width: 99%;
//   //   display: flex;
//   //   align-items: center;
//   //   justify-content: flex-end;
//   //   border-radius: 10px;
//   // }
// }
