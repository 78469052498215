.addProjectAttr {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-form-item {
        margin-bottom: 1rem;

        .ant-form-item-control {
            padding: 0 0 !important;
        }
    }
    button {
        padding: 5px 3px;
        margin: 0 0 11px 10px;
        border: none;
        background: transparent;
        border-radius: 50%;

        svg {
            height: 22px;
            width: 22px;
            path{
                fill:@secondary-color
            }
        }
    }
}