img {
  max-width: 100%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: @text-color;
}

html,
body {
  font-size: 62.5%;
}

* {
  scrollbar-color: #b5b8bb #e9e9e9;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #e9e9e9;
  }

  ::-webkit-scrollbar-thumb {
    background: #b5b8bb;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: #93999e;
    }
  }

  &::selection {
    background: @secondary-color;
    color: @white;
  }
}

html body {
  font-size: 1.6rem;
  color: @text_dark_black;
  font-family: @primary-font;
  font-weight: normal;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  min-width: 360px;
  line-height: normal;
}

.main__app__content {
  padding: 10px;
  max-height: calc(100vh - 52px);
  // max-height: calc(100vh - 70px);
  overflow-y: auto;

  // @media screen and (max-width:1200px) {
  //   max-height: calc(100vh - 90px);
  // }
}

::-webkit-input-placeholder {
  /* Edge */
  color: rgba(0, 0, 0, 0.6);
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.6);
}

::placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.loginForm .ant-form .ant-input:-webkit-autofill,
.loginForm .ant-form .ant-input:-webkit-autofill:hover,
.loginForm .ant-form .ant-input:-webkit-autofill:focus,
.loginForm .ant-form .ant-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px @white inset !important;
}

.fullscreen__spinner__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  .ant-spin-dot-item {
    background-color: @secondary-color;
  }
}

.userMenuList {
  .ant-dropdown-menu {
    padding: 6px 0;
  }
}

.headerBtn {
  .addBtn {
    padding: 1.2rem 1.3rem;
    height: auto;
    line-height: 1.6rem;
    border-radius: 0.8rem;
    font-size: 1.4rem;
    .semiMediumFonts();
    border-color: #d0d0d0;
    margin-left: 0;
    background: transparent;
    box-shadow: none;
    text-shadow: none;
    transition: all 0.5s ease;

    &:hover {
      color: @white;
      border-color: @primary-color;
      background: @primary-color;

      svg {
        color: @white;
      }
    }
  }
}

.iconBtn {
  width: 4.2rem;
  height: 4.2rem;
  background-color: @white;
  border-radius: 0.8rem;
  cursor: pointer;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
  border: 0;
  padding: 6px;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    transition: all 0.5s ease;
  }

  &:hover svg {
    fill: @primary-color;
  }
}

.ant-input {
  &::placeholder {
    display: none;
  }
}

.ag-root-wrapper-body.ag-layout-normal {
  flex: 1 1 auto;
  height: 100% !important;
  min-height: 0;
}

.ag-grid-wrapper .ag-cell {
  .ant-select-single:not(.ant-select-customize-input) {
    margin: 0 2px;
    max-width: 230px;

    .ant-select-selector {
      height: 32px;
      font-size: 14px;
      border: 1px solid #ddd;
      border-radius: 5px;

      .ant-select-selection-search-input {
        height: 32px;
      }
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 32px;
  }

  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-placeholder,
  .ant-select:not(.ant-select-multiple)
    .ant-select-selector
    .ant-select-selection-placeholder {
    line-height: 31px;
  }
}

.redNote {
  color: @error-color;
  margin-bottom: 15px;
}

.secondaryBtn {
  background: @secondary-color !important;
  border: 1px solid @secondary-color !important;
  text-shadow: none;
  box-shadow: none;
  color: @white !important;
  // height: auto;
  height: 35px;
  line-height: 1;
  min-width: 100px;
  padding: 0.8rem 1rem 0.8rem 1.2rem;
  margin-right: 1rem;
  border-radius: 0.8rem;
  transition: all 0.5s ease;
  outline: 0;

  span {
    .regulerFonts();
    font-size: 1.5rem;
  }

  button {
    background: @secondary-color !important;
    border: 1px solid @secondary-color !important;
    text-shadow: none;
    box-shadow: none;
    color: @white !important;
    font-size: 14px;
    height: auto;
    border-radius: 0.8rem;
    padding: 0 0;
    transition: all 0.5s ease;
    outline: none;
  }

  &:hover {
    background: transparent !important;
    border: 1px solid @secondary-color;

    span {
      color: @secondary-color;
    }

    .btnIcon {
      svg {
        stroke-width: 0;

        path {
          fill: @secondary-color;
        }
      }
    }
  }

  &.ant-tooltip-disabled-compatible-wrapper {
    padding: 0 0;
    border: none !important;

    button {
      padding: 0.8rem 2.5rem;

      span {
        color: @white;
      }
    }
  }

  &:hover .plusIcon {
    path {
      fill: @secondary-color;
    }
  }

  @media screen and(max-width:880px) {
    margin-right: 1rem;

    span {
      font-size: 1.3rem;
    }
  }
}

.plusIcon {
  margin-left: 10px;

  @media screen and(max-width:880px) {
    margin-left: 5px;
  }
}

.cancelBtn {
  background: transparent !important;
  border: 1px solid @secondary-color !important;
  text-shadow: none;
  box-shadow: none;
  color: @secondary-color !important;
  height: 35px;
  min-width: 110px;
  padding: 8px 25px;
  margin-right: 10px;
  border-radius: 8px;
  transition: all 0.5s ease;
  outline: none;
  line-height: 1;

  span {
    .regulerFonts();
    font-size: 1.5rem;

    @media screen and(max-width:850px) {
      font-size: 1.3rem;
    }
  }

  &:hover {
    border: 1px solid @cancel-color !important;

    span {
      color: @cancel-color;
    }
  }
}

.prevBtn {
  background: transparent !important;
  color: @secondary-color !important;
  border: 1px solid @secondary-color !important;
}

.ant-select {
  .ant-select-selector {
    .ant-select-selection-item {
      .regulerFonts();
      font-size: 1.5rem;
    }
  }
}

// ag-grid

.ag-root-wrapper {
  .ag-root-wrapper-body {
    .ag-header {
      padding: 1rem 1rem 0 1rem;
    }

    .ag-body-viewport {
      padding: 0 1rem 1.5rem 1rem;
    }
  }

  .ag-paging-panel {
    .ag-paging-row-summary-panel {
      span {
        .regulerFonts();
        font-size: 1.4rem;
      }

      .ag-paging-page-summary-panel {
        span {
          .regulerFonts();
          font-size: 1.4rem;
        }
      }
    }
  }
}

.ag-body {
  margin-top: 1rem;
}

.ag-filter {
  .ag-filter-wrapper {
    &.ag-focus-managed {
      .ag-input-wrapper {
        .ag-number-field-input {
          background-color: @white;
          border: 1px solid #ddd;
        }
      }
    }
  }
}

// ag-grid row focus
.ag-theme-alpine {
  --ag-selected-row-background-color: rgba(46, 46, 46, 0.2);

  .ag-root:not(.ag-context-menu-open)
    .ag-body-viewport:not(.ag-has-focus)
    .ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
    background-color: transparent !important;
  }
}

// breadcrumb
.mainPageHeader {
  .ant-breadcrumb {
    padding-left: 0;

    .ant-breadcrumb-link {
      font-size: 1.3rem;
      .regulerFonts();

      a {
        font-size: 1.3rem;
        .regulerFonts();

        @media screen and(max-width:891px) {
          font-size: 1.2rem;
        }
      }

      @media screen and(max-width:891px) {
        font-size: 1.2rem;
      }
    }

    ol {
      li {
        &:last-child {
          .ant-breadcrumb-link {
            a {
              cursor: default;
              background-color: transparent !important;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1092px) {
    .btnWrap {
      button {
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 12px;
          height: 12px;
          margin-top: 0;
        }

        span {
          font-size: 1.2rem;
        }

        min-width: 82px;
      }

      .archiveBtn {
        span {
          font-size: 1.2rem;
        }

        svg {
          width: 18px;
          height: 18px;
          margin-top: 0;
        }

        min-width: 100px;
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      }

      .ant-select {
        width: 125px !important;

        .ant-select-selector {
          .ant-select-selection-item {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 891px) {
    nav {
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }

    // .btnWrap {
    //   justify-content: flex-start !important;
    //   width: 100%;
    //   button{
    //     width: 100%;
    //   }
    //   .ant-select{
    //     width: 100%;
    //   }
    // }
  }
}

.action-column {
  button {
    svg {
      width: 18px;
      height: 18px;

      &:hover {
        path {
          stroke: @secondary-color;
          fill: @secondary-color;
        }
      }
    }
  }
}

// comman modal

.commanModal {
  .ant-modal-body {
    padding: 2.2rem 2.4rem 0 2.4rem !important;
  }

  form {
    width: 100%;

    .ant-form-item {
      width: 100%;
      margin-bottom: 10px;

      .ant-form-item-row {
        display: flex;
        flex-direction: column;

        .ant-form-item-label {
          text-align: start;
          white-space: inherit !important;
          margin-left: 14px;

          label {
            font-size: 1.4rem;
            color: @font-color;
            .regulerFonts();
            white-space: inherit;
            height: inherit;
            align-items: flex-start;
            min-height: 20px;
          }
        }
      }

      .ant-form-item-control {
        padding: 0 12px;

        .ant-form-item-control-input-content {
          .ant-select {
            .ant-select-selector {
              background-color: @input-color;
              border-radius: 4px;
            }

            .ant-select-arrow {
              background-color: @dropdown-color;
              border-radius: 4px 4px 0 0;
              height: 100%;
              width: 4.2rem;
              top: 0.6rem;
              right: 0;
              justify-content: center;
            }
          }

          .ant-checkbox-group {
            .ant-checkbox-wrapper {
              span {
                font-size: 1.4rem;
                color: #181c32;
                .regulerFonts();
                text-transform: capitalize;
              }
            }
          }
        }

        .ant-input {
          background-color: @input-color;
          border-radius: 4px;
        }

        .ant-picker {
          background-color: @input-color;
          border-radius: 4px;

          .ant-picker-input {
            input {
              font-family: @primary-font;
              font-size: 1.4rem;
            }
          }
        }

        .ant-form-item-explain {
          margin: 7px 0;
        }
      }
    }
  }

  .ant-modal-footer {
    margin-top: 0 !important;
  }
}

// alert modal
.alertModal {
  .ant-modal-body {
    border-top: 5px solid @secondary-color;
    border-radius: 8px;
  }

  .alertMsg {
    font-family: @primary-font;
    font-size: 1.6rem;
  }
}

.deleteModal {
  .ant-modal-content {
    .ant-modal-header {
      border-top: 4px solid @cancel-color;
      padding: 16px 33px 16px 24px;

      .ant-modal-title {
        .semiMediumFonts();
        color: @primary-color;
        word-wrap: break-word;
      }
    }

    .ant-modal-body {
      padding: 1.6rem 2.4rem 0 2.4rem;

      .deleteNote {
        font-size: 16px;
        .regulerFonts();
        color: @text-color;
      }
    }

    .ant-modal-footer {
      .deleteBtn {
        background: @cancel-color !important;
        border: 1px solid @cancel-color !important;
        text-shadow: none;
        box-shadow: none;
        color: @white !important;
        font-size: 14px;
        height: 35px;
        line-height: 1;
        min-width: 110px;
        padding: 8px 25px;
        margin-right: 10px;
        border-radius: 8px;
        transition: all 0.5s ease;
        outline: none;

        span {
          .regulerFonts();
          font-size: 1.5rem;
        }

        &:hover {
          background-color: transparent !important;
          border: 1px solid @cancel-color !important;

          span {
            color: @cancel-color;
          }
        }
      }
    }
  }
}

//  comman icons
.deleteIcon {
  svg {
    height: 22px !important;
    width: 22px !important;

    path {
      fill: white;
    }

    &:hover {
      path {
        stroke: @error-color !important;
        fill: transparent !important;
      }
    }
  }
}

.menuIcon {
  background-color: transparent;
  padding: 0 0;
  border: none;
  line-height: 3;

  &:hover {
    svg {
      circle {
        stroke: @secondary-color;
      }
    }
  }
}

.StructureIcon {
  padding: 0 0 !important;
  line-height: 1;

  svg {
    width: 21px;
    height: 21px;
  }
}

.reportIcon {
  svg {
    stroke-width: 0.7px;
  }
}

.projectIcon {
  path {
    stroke: #181c32;
  }

  &:hover {
    path {
      fill: transparent !important;
    }
  }
}

// common responsive
@media screen and (max-width: 991px) {
  .ant-col-8,
  .ant-col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

// comman Form label
.ant-form-item-row {
  display: flex;
  flex-direction: column;

  .ant-form-item-label {
    text-align: start;
    white-space: inherit !important;

    // height: 50px;
    label {
      font-size: 1.4rem;
      color: @font-color;
      .regulerFonts();
      white-space: inherit;
      height: inherit;
      align-items: flex-start;
      min-height: 33px;

      @media screen and (max-width: 1600px) {
        font-size: 1.3rem;
      }
    }
  }

  .ant-input {
    background-color: #f2f2f2 !important;
    border-radius: 4px;
    padding: 0 12px;
  }

  .ant-picker {
    background-color: #f2f2f2 !important;
    border-radius: 4px;
    padding: 0 12px;

    .ant-picker-input {
      input {
        font-family: @primary-font;
        font-size: 1.4rem;
        font-weight: 400;
      }
    }
  }
}

// common astrik sign
.ant-form-item-label {
  label {
    &::after {
      display: none;
    }
  }
}

.ant-form-item {
  .ant-form-item-control {
    .ant-form-item-control-input {
      .ant-form-item-control-input-content {
        .ant-input {
          &:focus {
            border: 1px solid @secondary-color;
          }
        }

        .ant-picker-focused {
          border: 1px solid @secondary-color;
        }

        .ant-input-number-focused {
          border: 1px solid @secondary-color;
        }

        .ant-select-focused {
          border: 1px solid @secondary-color;
        }
      }
    }
  }
}

.input-height-auto {
  height: auto !important;

  .ant-form-item-control-input {
    min-height: auto;
  }

  .ant-form-item-control {
    min-height: auto;
  }
}

// form heading

.formHeading {
  h3 {
    background: white;
    color: #353d40;
    font-size: 1.5rem;
    .regulerFonts();
    margin-bottom: 0;
    max-width: 100%;
    width: fit-content;
    padding: 7px 40px;
    margin: 0 6rem;
    border-radius: 7px 7px 0 0;
    border-top: @PrimarythinBorder;
    border-right: @PrimarythinBorder;
    border-left: @PrimarythinBorder;
    position: relative;
  }
}

.afterLine {
  border-right: 1px solid black;
}

// action row common button

.actionRow {
  background-color: @white;
  padding: 1rem 2rem;
  border-radius: 10px;
  border: @PrimarythinBorder;
  margin-top: 15px;
  position: absolute;
  bottom: 0;
  width: 100%;

  @media screen and (max-height: 650px) {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .actionBtn {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .activeBtn {
      background-color: @primary-color;
      margin-right: 10px;
      display: flex;
      align-items: center;
      padding: 6px 22px;
      height: 35px;
      margin: 7px;

      span {
        color: @white;
      }

      svg {
        height: 20px;
        width: 20px;
        margin-left: 10px;

        path {
          fill: @white;
        }
      }

      &:hover {
        background-color: transparent;
        border: 1px solid @primary-color !important;

        span {
          color: @primary-color;
        }

        svg {
          path {
            fill: #2f373a;
            stroke: #2f373a;
            stroke-width: 1;
          }
        }
      }

      @media screen and (max-height: 850px) {
        span {
          font-size: 1.3rem;
        }
      }
    }

    .deleteBtn {
      background-color: @primary-color;
      margin-right: 10px;
      display: flex;
      align-items: center;
      padding: 6px 22px;
      height: 35px;
      margin: 7px;

      span {
        color: @white;
      }

      svg {
        height: 20px;
        width: 20px;
        margin-left: 10px;

        path {
          fill: transparent !important;
          stroke: @white !important;
          stroke-width: 2;
        }
      }

      &:hover {
        background-color: transparent;
        border: 1px solid @primary-color;

        span {
          color: @primary-color;
        }

        svg {
          path {
            stroke: @primary-color !important;
            stroke-width: 2;
          }
        }
      }
    }

    .projectBtn {
      svg {
        path {
          fill: transparent !important;
          stroke: @white !important;
          stroke-width: 70;
        }
      }

      &:hover {
        svg {
          path {
            stroke: transparent !important;
            fill: @primary-color !important;
          }
        }
      }
    }
  }

  .reinforcementIcon {
    stroke-width: 0.2;
  }

  // common disable btn

  .disableBtn {
    background-color: #d2d2d3 !important;
    margin-right: 10px;
    display: flex;
    border-radius: 6px;
    align-items: center;
    padding: 6px 22px;
    height: 35px;
    margin: 7px;
    border: 1px solid transparent;

    span {
      color: @white;
    }

    svg {
      height: 20px;
      width: 20px;
      margin-left: 10px;

      path {
        fill: @white;
        stroke: @white;
      }
    }

    &:hover {
      background-color: transparent;
      border: 1px solid transparent !important;

      span {
        color: @white;
      }

      svg {
        path {
          fill: @white;
          stroke: @white;
          stroke-width: 1;
        }
      }
    }

    button {
      width: 100%;
      padding: 0 0;
      border: none;
      background: transparent !important;

      span {
        width: 100%;
      }
    }

    @media screen and (max-width: 1150px) {
      margin-top: 15px;
    }

    @media screen and (max-width: 991px) {
      padding: 6px 22px;
    }
  }

  .disableDelBtn {
    cursor: not-allowed;

    svg {
      path {
        fill: transparent !important;
        stroke-width: 2;
      }
    }

    &:hover {
      svg {
        path {
          stroke-width: 2;
        }
      }
    }
  }

  .tooltipBtn {
    button {
      display: flex;
      align-items: center;
      height: 24px;
    }

    @media screen and (max-width: 1150px) {
      margin-top: 15px;
    }
  }
}

.auditActionBtn {
  position: absolute;
  bottom: 0;
  width: 100%;

  @media screen and (max-width: 1080px) {
    bottom: 50px;
  }
}

.rawmaterialActionRow {
  position: absolute;
  bottom: 0;
  width: 100%;
}

// upload view icon

.ant-upload-wrapper {
  .ant-upload-list-item-container {
    .ant-upload-list-item-actions {
      a {
        pointer-events: visible !important;
        opacity: 1 !important;
      }
    }
  }

  .ant-upload-select {
    width: 115px !important;
    height: 115px !important;
  }

  .ant-upload-list-item-container {
    width: 115px !important;
    height: 115px !important;
  }
}

//  comman css for checkbox

.commanCheckbox {
  .ant-checkbox {
    margin-top: 0;
  }
}

// comman drawer css
.ant-drawer {
  @media screen and(max-width:850px) {
    .ant-drawer-content-wrapper {
      width: 90% !important;
    }
  }
}

.ant-drawer-header {
  .ant-drawer-header-title {
    .ant-drawer-close {
      svg {
        path {
          fill: @white;
        }
      }
    }
  }
}

// role css
.titleRow {
  padding: 5px 5px;
  margin: 7px 0;
  border: 1px solid #f1f1f1;
  border-radius: 8px;
}

.titleCol {
  .subTitle {
    font-size: 14px;
    color: @primary-color;
    font-weight: 600;
  }
}

// comman select
.userSelect {
  .ant-select {
    .ant-select-selector {
      height: 40px;
      border-radius: 4px !important;
      background-color: #f2f2f2 !important;
    }
  }
}

.selectedFirm {
  .ant-select-arrow {
    background-color: transparent !important;
  }
}

.notCol {
  margin: 0.5rem 0 1rem 0;
  width: 100%;

  .passwordNote {
    color: @secondary-color;
    font-weight: 500;
    font-family: @primary-font;
  }

  .passwordNoteMsg {
    color: @primary-color;
    font-weight: 400;
    font-family: @primary-font;
    // color: rgb(219, 184, 85);
    color: @primary-color;
  }
}

// spinner
.ant-spin {
  .ant-spin-dot {
    .ant-spin-dot-item {
      background-color: @primary-color;
    }
  }
}

.loadingSpinner {
  path {
    fill: #5a5a5a;
  }

  animation: loadingSpinner 1s linear infinite;
}

@keyframes loadingSpinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

//User Manangement css
.ant-input-affix-wrapper {
  padding: 0 12px;
  background-color: #f2f2f2;
  border: none;

  &.ant-input-affix-wrapper-focused {
    .ant-input {
      border: none !important;
      box-shadow: none !important;
    }
  }
}

.userManagmentForm {
  .permissionForm {
    margin: 20px 0;
  }

  @media screen and(max-width:991px) {
    .ant-col-md-8 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.modifyPermissionBtn {
  background-color: transparent;
  border: 1px solid @secondary-color;

  span {
    color: @secondary-color;
  }

  &:hover {
    border: 1px solid @primary-color;

    span {
      color: @primary-color;
    }
  }
}

.disablePermissionBtn {
  background-color: #f2f2f2;

  span {
    color: #646464;
  }
}

.ant-form-item-control-input-content {
  .ant-select {
    .ant-select-selector {
      background-color: @input-color !important;
      border-radius: 4px;
    }

    .ant-select-arrow {
      background-color: @dropdown-color;
      border-radius: 4px 4px 0 0;
      height: 100%;
      width: 4.2rem;
      top: 0.6rem;
      right: 0;
      justify-content: center;
    }
  }
}

.permissionCol {
  display: flex;
  align-items: center;
}

// for scroll remove
.listWrapper {
  height: 100%;

  .auditListWrapper {
    height: calc(100% - 100px);
    border: @PrimarythinBorder;
    border-radius: 8px;
  }

  .auditListing {
    height: 100%;
  }

  .stepContainer {
    margin-top: 2.5rem !important;
    height: calc(100% - 75px) !important;
  }
}

.list {
  height: calc(100% - 80px);
  border: @PrimarythinBorder;
  border-radius: 8px;

  // @media screen and (max-height: 1380px) {
  //   height: calc(100% - 100px);
  // }

  // @media screen and (max-height: 1080px) {
  //   height: calc(100% - 85px);
  // }

  // @media screen and (max-width: 1200px) {
  //   height: calc(100% - 90px);
  // }

  // @media screen and (max-width: 991px) {
  //   height: calc(100% - 107px);
  // }
}

.mainLayoutAuditWrapper {
  .auditList {
    @media screen and (max-width: 995px) {
      height: calc(100vh - 145px);
    }
  }
}

.auditList {
  // height: 63vh;
  height: calc(100% - 133px);

  .ant-select,
  .dateUserPicker {
    .ant-select-clear,
    .ant-picker-clear {
      right: 12px;
      top: 44%;
      width: 17px;
      height: 17px;
      // color: #3577c5;
      font-size: 17px;
      background: white;
      border-radius: 50%;
    }

    .ant-select-selector {
      .ant-select-selection-placeholder {
        font-family: @primary-font;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .dateUserPicker {
    .ant-picker-clear {
      right: -1px;
    }

    &.ant-picker-focused {
      border: 1px solid @secondary-color;
    }

    .ant-picker-input {
      input {
        &::placeholder {
          font-family: @primary-font;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
.auditModuleList {
  // height: 63vh;
  height: calc(100vh - 121px);

  .ant-select,
  .dateUserPicker {
    .ant-select-clear,
    .ant-picker-clear {
      right: 12px;
      top: 44%;
      width: 17px;
      height: 17px;
      // color: #3577c5;
      font-size: 17px;
      background: white;
      border-radius: 50%;
    }

    .ant-select-selector {
      .ant-select-selection-placeholder {
        font-family: @primary-font;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .dateUserPicker {
    .ant-picker-clear {
      right: -1px;
    }

    &.ant-picker-focused {
      border: 1px solid @secondary-color;
    }

    .ant-picker-input {
      input {
        &::placeholder {
          font-family: @primary-font;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
.structureList {
  border: @PrimarythinBorder;
  border-radius: 8px;
  height: calc(100% - 105px);

  @media screen and (max-height: 1080px) {
    height: calc(100% - 105px);
  }

  @media screen and (max-width: 1100px) {
    height: calc(100% - 115px);
  }

  @media screen and (max-width: 1086px) {
    height: calc(100% - 175px);
  }

  @media screen and (max-width: 970px) {
    height: calc(100% - 175px);
  }

  @media screen and (max-width: 760px) {
    height: calc(100% - 230px);
  }
}

// common modal
.commanModal {
  .ant-modal-header {
    .mediumFonts();
  }

  .ant-modal-body {
    .deleteNote {
      .regulerFonts();
    }
  }
}

.listBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    margin-right: 5px;
    stroke-width: 0;

    path {
      stroke: @white;
    }
  }

  &:hover {
    svg {
      path {
        stroke: @secondary-color;
      }
    }
  }

  .activeIcon {
    background-color: #02ff02;
    width: 10px;
    height: 10px;
    margin-right: 7px;
    border-radius: 50%;
  }
}

.archiveBtn {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 5px;

    path {
      stroke: @white;
    }
  }

  &:hover {
    svg {
      path {
        stroke: @secondary-color;
      }
    }
  }
}

.arrow {
  position: absolute;
  top: 70%;
  right: 80px;
  transform: translate(-50%, -50%) rotate(-90deg);
  cursor: pointer;
  z-index: 999;
}

.arrow span {
  display: block;
  width: 1.2vw;
  height: 1.2vw;
  border-bottom: 5px solid @secondary-color;
  border-right: 5px solid @secondary-color;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s forwards 3;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

.projectLabel {
  width: 80%;
  padding: 7px 10px;
  text-align: center;
  border-radius: 10px;

  .projectName {
    .mediumFonts();
    font-size: 13px;
    color: #3b3939;
  }

  .projectDate {
    .regulerFonts();
    font-size: 12px;

    span {
      color: #3b3939;
      font-weight: 500;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 75%;
  }

  @media screen and (max-width: 1024px) {
    width: 70%;
  }

  @media screen and (max-width: 850px) {
    width: 56%;
  }
}

.btnWrap {
  .secondaryBtn {
    .btnIcon {
      stroke-width: 0;
      margin-left: 0.7rem;
    }
  }

  .pileBtn {
    min-width: 100px;

    &:hover {
      button {
        .btnIcon {
          svg {
            path {
              fill: @white;
            }
          }
        }
      }
    }
  }
}

.auditTable {
  display: flex;
  align-items: flex-start;
  width: 100%;
  border: 1px solid #e2e2e2;

  tr {
    border: 1px solid #e2e2e2;
    text-align: center;
  }

  td {
    border-right: 1px solid #e2e2e2;
    padding: 10px 20px;
    .regulerFonts();

    word-break: break-word;
    max-width: 140px;
  }

  .projectLabel .updateHeader {
    td {
      .mediumFonts();
      font-size: 15px;
      background-color: #f3f3f3;
    }
  }

  .subHeader {
    td {
      &:nth-child(1) {
        .mediumFonts();
      }

      &:nth-child(3) {
        .mediumFonts();
      }
    }
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
}

.oldTable {
  width: 50%;
}

.newTable {
  width: 50%;
}

.auditModal {
  width: 70% !important;
}

.auditBtn {
  display: flex;
  align-items: center;
  // margin-bottom: 10px;

  .auditBtnIcon {
    margin: 0 10px;

    button {
      line-height: 3.5;
      border: 1px solid transparent;
      height: 42px;

      svg {
        height: 25px;
        width: 25px;

        path {
          fill: @secondary-color;
        }
      }

      &:hover {
        border: 1px solid @secondary-color;
        background-color: transparent;
      }
    }
  }
}

.btnList {
  background: white;
  padding: 13px 0;
  display: flex;
  justify-content: flex-end;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.auditSelect {
  .ant-select {
    width: 190px !important;
    margin: 0 15px;
    border: 1px solid transparent !important;

    .ant-select-arrow {
      border-radius: 4px;
    }

    &.ant-select-focused {
      border-radius: 4px;
      border: 1px solid #3e97ff !important;
    }

    @media screen and (max-width: 1250px) {
      width: 150px !important;
    }
  }

  .ant-select-selector {
    height: 100%;
    border-radius: 4px;
    padding: 0 16px;
  }
}

.dateUserPicker {
  width: 200px !important;
  margin-right: 15px;
  border-radius: 4px;
  background-color: #f2f2f2 !important;

  @media screen and (max-width: 1250px) {
    width: 150px !important;
  }
}

.ant-select {
  &.ant-select-in-form-item {
    height: 100% !important;
  }
}

// Mix Design

.mixDesignTable {
  .ant-table {
    table thead {
      .tableTitle {
        max-width: fit-content;

        span {
          margin-left: 5px;
        }
      }
    }
  }

  .ant-table-cell {
    text-align: left;
    width: 230px;
  }

  .nameCol {
    ::before {
      height: 100% !important;
    }
  }
}

.mixListTable {
  overflow-x: auto;
  height: 100%;
  background: #fff;
  border: 1px solid #d1d3d3;
  border-radius: 8px;

  .mixBtn {
    margin: 10px 10px 0 10px;
    display: flex;
    justify-content: flex-end;

    button {
      margin-right: 0;
    }
  }

  table {
    background-color: transparent;
    border: none !important;
  }

  .mixDesignTable {
    height: calc(100% - 45px);
  }
}

.mixDesignForm {
  .ant-select {
    .ant-select-clear {
      right: 15px;
    }
  }
}

// comman modal opacity
.ant-modal-root {
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

// warning modal

.warningModal {
  .ant-modal-body {
    .deleteNote {
      font-size: 16px;
      .regulerFonts();
      color: @text-color;
    }
  }

  .ant-modal-footer {
    margin-top: 0;
  }
}

.endDateStructure {
  @media screen and (max-width: 991px) {
    .ant-form-item {
      margin-top: 0 !important;
    }
  }
}

.listIcon {
  svg {
    margin: 0.2rem 0 0 0.5rem !important;
  }
}

// report
.reportList {
  height: calc(100% - 100px) !important;
  background-color: white;

  .listRangePicker {
    max-width: 240px;
    margin-right: 10px;

    .ant-picker-range {
      border-radius: 8px !important;
    }

    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }

  .btnList {
    padding: 20px 20px 0 20px;

    button {
      height: 39px;
      padding: 7px 14px;
    }
  }
}

// diable btn
.diableBtnWrap {
  height: auto;
}

//  to remove outline from button

.ant-btn:not(:disabled):focus-visible {
  outline: none !important;
}

// dropdown select
.dpdQuestion {
  .ant-form-item {
    width: 100%;
  }
}

// no data box
.noDataBox {
  height: 200px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  margin-top: 0;

  p {
    font-family: @primary-font;
    font-weight: 500;
    font-size: 14px;
    margin-top: 10px;
  }
}

// add icon
.plusIconWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  label {
    font-size: 14.5px;
    font-family: @primary-font;
    color: #000000e6;
    // padding-bottom: 20px;
    position: relative;
    display: flex;
  }

  .infoIcon {
    display: flex;
    align-items: center;
  }

  .addIcon {
    height: 25px;
    width: 25px;
    position: absolute;
    right: -30px;
    top: 0;

    svg {
      height: 15px;
      width: 15px;
    }
  }

  .disabledAddIcon {
    padding: 0;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin-left: 10px;
    background-color: rgb(194, 194, 194);
    border: 1px solid transparent;

    svg {
      height: 14px;
      width: 14px;

      path {
        fill: white;
      }
    }
  }
}

.addIcon {
  padding: 0;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-left: 10px;
  background-color: @secondary-color;
  border: 1px solid transparent;

  svg {
    height: 14px;
    width: 14px;

    path {
      fill: white;
    }
  }

  &:hover {
    background-color: transparent;
    border: 1px solid @primary-color;

    svg {
      path {
        fill: @primary-color !important;
      }
    }
  }
}

.removeIcon {
  padding: 0;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-left: 10px;
  background-color: @secondary-color;
  border: 1px solid transparent;
  position: absolute;
  bottom: 40px;
  cursor: pointer;

  svg {
    height: 14px;
    width: 14px;

    g {
      path {
        stroke: white;
      }
    }
  }

  &:hover {
    background-color: transparent;
    border: 1px solid @secondary-color;

    svg {
      height: 14px;
      width: 14px;

      g {
        path {
          stroke: @secondary-color;
        }
      }
    }
  }
}

// btn
.disableRemove {
  padding: 0;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-left: 10px;
  border: 1px solid transparent;
  position: absolute;
  bottom: 40px;
  cursor: pointer;

  svg {
    g {
      path {
        stroke: grey;
      }
    }
  }
}

// update

// .flexBtn {
//   display: flex;

.btnLists {
  display: flex;
  align-items: center;

  button {
    margin-right: 0;
  }

  .auditSelect {
    .ant-select {
      margin: 0 5px 0 0;

      .ant-select-selector {
        background-color: #fff !important;
      }
    }

    .ant-select-arrow {
      background-color: #e1e0e0;
    }
  }

  .dateUserPicker {
    margin-right: 10px;
    background-color: #fff !important;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    height: 35px;

    @media screen and (max-width: 880px) {
      width: 110px !important;
    }
  }

  @media screen and (max-width: 1250px) {
    button {
      min-width: 90px;
      padding: 0.8rem;
    }
  }

  @media screen and (max-width: 900px) {
    .auditBtn {
      .ant-select {
        width: 120px !important;

        .ant-select-selector {
          .ant-select-selection-item {
            font-size: 1.1rem;
          }
        }
      }
    }
  }

  // }
  // select height auto
  .ant-select {
    height: auto;
  }

  // comman remark col

  .remarkCol {
    display: flex;
    align-items: flex-start;
    width: 100%;

    .ant-form-item {
      width: 99%;
    }
  }
}

.remarkCol {
  .ant-form-item {
    width: 100%;
  }
}

// btnwrap select alignment
.btnWrap {
  .ant-select-selector {
    height: 35px !important;
    line-height: 1;

    .ant-select-selection-item {
      line-height: 35px !important;
    }

    .ant-select-selection-search-input {
      height: 35px !important;
    }

    .ant-select-selection-placeholder {
      line-height: 35px !important;
    }
  }
}

.rawMaterialFormWrapper {
  height: calc(100vh - 125px);
}

.roadReportFormWrapper {
  #addForm {
    height: calc(100vh - 125px);
  }
}

.departmentWrap {
  height: 100%;
}

.mainDetailsWrapper {
  height: calc(100vh - 90px);
}

// planning form

.planningForm {
  height: calc(85vh - 30px);

  .addReportForm {
    height: calc(85vh - 30px);
    overflow: auto;

    @media screen and (max-height: 820px) {
      height: calc(100% - 30px);
    }

    @media screen and (max-height: 650px) {
      height: calc(85vh - 72px);
    }

    @media screen and (max-width: 780px) {
      height: calc(85vh - 100px);
    }
  }

  @media screen and (max-height: 650px) {
    height: calc(100% - 55px);
  }
}

// work list
.listWrapper {
  .workList {
    height: calc(100% - 80px);
    border: 1px solid #e0e0e0;
    border-radius: 8px;
  }
}

// comman heading form
.mainUpperHeadingContainer {
  background: transparent;
  position: relative;
  height: auto;
  overflow: auto;

  .planningContainer {
    position: relative;

    form {
      background-color: @white;
      padding: 3rem;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      border-top: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
      border-left: 1px solid #e0e0e0;

      .ant-form-item {
        height: 90px;

        .ant-form-item-control-input {
          min-height: auto;
        }
      }

      .uploadRow {
        display: flex;
        flex-direction: column;

        label {
          font-size: 1.4rem;
          color: @font-color;
          font-weight: 400;
          font-family: @primary-font;
          white-space: inherit;
          height: inherit;
          align-items: flex-start;
          min-height: 27px;

          @media screen and (max-width: 1600px) {
            font-size: 1.3rem;
          }
        }

        .ant-upload-wrapper {
          .ant-upload-select {
            background-color: #f2f2f2;
            border-radius: 4px;
          }
        }
      }

      @media screen and(max-width:991px) {
        .ant-col-12 {
          max-width: 100%;
          flex: 0 0 100%;
        }
      }
    }

    .stepBtn {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding: 20px;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
      background-color: @white;
      border-bottom: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
      border-left: 1px solid #e0e0e0;
    }
  }
}

// road department
.roadDepartment {
  .projectInfo {
    .dateUserPicker {
      width: 200px !important;
      margin-right: 0;
      border-radius: 4px;

      .ant-picker {
        background-color: #fff !important;
        height: 35px;
        border-radius: 8px;
      }

      @media screen and (max-width: 1450px) {
        width: 180px !important;
      }

      @media screen and (max-width: 1200px) {
        width: 130px !important;

        .ant-picker-input {
          input {
            &::placeholder {
              font-size: 1.2rem;
            }
          }
        }
      }

      @media screen and (max-width: 1024px) {
        width: 30% !important;
      }
    }

    .projectList {
      .ant-select {
        @media screen and (max-width: 1450px) {
          width: 160px !important;
        }

        @media screen and (max-width: 1200px) {
          width: 125px !important;

          .ant-select-selector {
            .ant-select-selection-item {
              font-size: 1.2rem;
            }
          }
          .ant-select-selection-placeholder {
            font-size: 1.1rem;
          }
        }

        @media screen and (max-width: 1024px) {
          width: 100% !important;
        }
      }

      @media screen and (max-width: 1024px) {
        width: 33% !important;
      }
    }

    .roadSearch {
      .ant-input {
        @media screen and (max-width: 1450px) {
          width: 160px !important;
        }

        @media screen and (max-width: 1200px) {
          width: 120px !important;

          &::placeholder {
            font-size: 1.2rem;
          }
        }
      }
    }

    @media screen and (max-width: 1024px) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
  @media screen and (max-height: 991px) {
    height: calc(86vh - 20px);
  }
}

.form-control-main {
  column-gap: 15px;
  .ant-col-4 {
    > .ant-form-item {
      position: relative;
      top: 12px;
    }
  }
  .ant-select-arrow {
    background-color: transparent !important;
  }
}

.projectList {
  .ant-select-arrow {
    background-color: transparent !important;
  }
}

.export-button {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  button {
    &.secondaryBtn {
      margin-right: 0;
      height: auto;
    }
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: #fff;
        stroke: #fff;
      }
    }
    &:hover {
      svg {
        path {
          fill: #3e97ff;
          stroke: #3e97ff;
        }
      }
    }
  }
}
.button-part {
  column-gap: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .secondaryBtn {
    margin: 0;
  }
}
.addReportForm {
  border: solid 1px #e0e0e0;
  border-top: 0;
}

.view-report-button {
  display: flex;
  align-items: center;
  column-gap: 5px;
  svg {
    fill: #fff;
  }
  &:hover {
    svg {
      fill: #3e97ff;
    }
  }
}
.mainPageWrap {
  .projectInfo {
    .newProjectWrap {
      button {
        margin-left: 10px;
        margin-right: 0;
      }
    }
  }
}

.ag-root-wrapper {
  &.ag-layout-normal {
    .ag-watermark {
      display: none !important;
    }
  }
}

.ag-header-row-column-group .ag-header-group-cell-label {
  //background-color: transparent !important; /* Makes background transparent */
  color: black !important; /* Sets text color to black */
  border: none !important; /* Removes border */
  font-weight: 500 !important; /* Sets font weight to normal */
  font-size: 15px !important;
  background-color: white;
}

/* Optional: Adjust padding and alignment */
.ag-header-group-cell .ag-header-group-cell-label {
  padding: 0 !important;
  justify-content: flex-start !important; /* Aligns text to the left */
}

.custom-group-header {
  background-color: white !important;
  color: black !important;
  border: none;
  font-weight: normal;
  padding: 0;
  display: flex;
  justify-content: center; /* Centers the label horizontally */
  align-items: center;
}
