footer {
  position: absolute;
  bottom: 0;
  background-color: @primary-color !important;
  div{
    color:@white !important;
    font-family: @primary-font;
    font-weight:400;
    font-size:1.3rem;
    span{
        color:@white !important;

    }
  }
}
