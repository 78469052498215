.loginSection {
  .loginWrap {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-wrap: wrap;
    min-height: auto;
    max-height: 100%;
    height: 100dvh;

    .loginLogo {
      width: 50%;
      text-align: center;
      position: relative;

      .loginBanner {
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: left;

        img {
          max-width: 90%;
          max-height: calc(100vh - 60%);
        }
      }

      .logo {
        position: absolute;
        right: 0;
        left: 0;
        margin: 0 auto;
        padding-top: 0;
        height: auto;
        background: #fff;
        width: 100%;
        max-width: 250px;
        border-radius: 0 0 145px 145px;
        box-shadow: 0 0 10px 17px rgb(0 0 0 / 5%);

        img {
          border-radius: 0 0 145px 145px;
          margin-top: 3.2rem;
        }
      }
    }

    .loginDesign {
      position: absolute;
      bottom: 0;
      z-index: 9;
      width: 100%;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  @media (max-width: 1600px) {
    .loginWrap .loginLogo .loginBanner img {
      max-width: 66%;
    }
  }
}

.loginForm {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 99;

  .loginFormWrap {
    max-width: 600px;
    width: 100%;
    .signBtn {
      .ant-btn {
        background-color: @secondary-color !important;
        border: none !important;
        outline: none !important;

        span {
          color: @white;
          font-family: @primary-font;
          font-weight: 400;
          font-size: 1.4rem;
        }
      }
    }

    .signUpBtn {
      background-color: transparent !important;
      border: none;

      span {
        font-family: @primary-font;
        font-weight: 400;
        font-size: 1.4rem;
      }

      &:hover {
        border: 1px solid transparent !important;
      }
    }
  }

  .formTitle {
    margin-bottom: 25px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    h2 {
      display: inline-block;
      font-size: 34px;
      margin: 0;
      color: @primary-color;
      font-family: @primary-font;
      font-weight: 400;
    }

    span {
      font-size: 14px;
      color: @primary-color;
      font-family: @primary-font;
      font-weight: 400;
    }
  }

  .ant-form {
    .ant-form-item {
      margin-bottom: 20px;

      .ant-input-suffix {
        color: @secondary-color;
        opacity: 0.8;
        margin-inline-start: 0 !important;

        .ant-input-password-icon {
          color: @secondary-color;
          position: absolute;
          right: 15px;

          &:hover {
            color: @black;
          }
        }
      }
    }

    .ant-form-item-row {
      position: relative;
    }

    .ant-form-item-label {
      overflow: visible;
      position: absolute;
      top: -12px;
      left: 20px;
      z-index: 99;
      background-color: @primary-color;
      padding: 0 5px;

      label {
        font-size: 15px;
        font-weight: 500;
        color: @white;
        letter-spacing: 0.5px;
      }
    }

    .ant-input,
    .ant-input-affix-wrapper {
      background-color: @white !important;
      padding: 0 40px 0 15px;
      height: 55px;
      font-family: @primary-font;
      font-weight: 400;
      // color: @white;
      border: 1px solid #ddd;
      border-radius: 5px;
    }

    .ant-input-password {
      padding: 0 0 0 0;
    }

    .ant-input-affix-wrapper {
      border: none;
      box-shadow: none;
      .ant-input {
        border: 1px solid #ddd;
        height: auto;
        border-radius: 5px;
        font-weight: 400;
        font-family: @primary-font;
        padding: 0 8px 0 15px;
      }
    }

    .ant-input-affix-wrapper-status-error {
      .ant-input {
        background-color: transparent !important;
      }
    }

    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      box-shadow: none;
    }

    input:-internal-autofill-selected {
      background-color: transparent !important;
    }

    .ant-input:focus,
    .ant-input-focused {
      box-shadow: none;
    }

    .ant-btn {
      font-size: 20px;
      font-weight: 500;
      background: #fff !important;
      border: 2px solid transparent;
      color: @primary-color !important;
      text-shadow: none;
      box-shadow: none;
      border-radius: 5px;
      height: auto;
      width: 100%;
      margin-top: 20px;
      transition: all 0.5s ease;

      &:hover {
        transform: none;
        color: @white !important;
        background-color: @secondary-color !important;
        // border-color: @secondary-color;
      }
    }
  }

  .ant-form-item-control-input {
    .ant-input-group-addon {
      position: absolute;
      right: 30px;
      top: 38%;
      border: none !important;
      background-color: transparent !important;
      // cursor: pointer;
      padding: 0 0 !important;
      z-index: 99;
    }
  }

  .login_extra_fields {
    font-family: @primary-font;
    font-size: 1.4rem;
    font-weight: 400;
  }
}

.unauthoriseModal {
  .loginForm {
    width: 100%;
    padding: 70px 30px;
  }

  &.ant-modal {
    .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      padding: 2px;
    }
  }
}