.progressWrap {
    height: calc(92vh - 20px);

    .progressChartLayout {
        // margin: 0px 7rem;
        overflow-x: hidden;
        height: 100%;

    .chartCard {
        border-radius: 5px;
        border: 1px solid #E6E6E6;
        background: #FFF;
        // margin: 0px 30px;
        margin: 0 0 10px 0;
        padding: 10px 0;

        .cardHeader {
            color: #182125;
            font-family: @primary-font;
            font-size: 1.8rem;
            font-weight: 500;
            position: relative;

            &::after {
                position: absolute;
                background-color: #E6E6E6;
                height: 1px;
                width: 100%;
                content: "";
            }
        }
    }

    .cardContent {
        margin: 6rem 0 1rem 0;
    }

    @media screen and (max-width:1100px) {
        margin: 0 0rem;
    }

    @media screen and (max-width:972px) {
        .chartCard {
            margin: 0 10px
        }
    }
}

.progressBarTotal {
    width: 97%;
    border: 1px solid #d7d6d7;
    height: 30px;
    position: relative;
    margin: 8rem 0;
    left: 20px;
    background: @secondary-color;

    .startDateProgress {
        position: absolute;
        height: 50px;
        background: @primary-color;
        width: 4px;
        left: -5px;
        top: -11px;
        border-radius: 12px;

        .startDate {
            position: absolute;
            width: 75px;
            bottom: -45%;
        }
    }

    .completed {
        position: absolute;
        height: 100%;
        z-index: 10;

        .completedLine {
            position: absolute;
            background-color: #ef4444;
            width: 4px;
            left: 100%;
            height: 50px;
            top: -20px;
            border-radius: 12px;

            .completedNumber {
                position: absolute;
                top: -25px;
                left: 0;
                width: 140px;
                color: rgb(134, 131, 131);
            }
        }

        .workLabel {
            position: absolute;
            top: -25px;
            left: -65px;
        }
    }

    .completedTotal {
        position: absolute;
        height: 100%;
        z-index: 10;

        .completedLine {
            position: absolute;
            background-color: #ef4444;
            width: 4px;
            height: 50px;
            top: -20px;
            border-radius: 12px;
            right: -5px;
        }
    }

    .goalProgress {
        position: absolute;
        height: 100%;
        width: 60%;
        z-index: 9;

        .goalProgressLine {
            position: absolute;
            background-color: #ff9900;
            width: 4px;
            left: 100%;
            height: 52px;
            top: 0;
            border-radius: 12px;

            .goalProgressNumber {
                position: absolute;
                left: -10px;
                position: absolute;
                bottom: -25px;
                width: 100px;
                color: rgb(134, 131, 131);

                @media screen and(max-width:1100px) {
                    left: -20px;
                }
            }

            .goalLabel {
                position: absolute;
                top: -25px;
                left: -25px;
            }
        }

        .goalProgressTotalLine {
            right: -60px;
        }
    }

    .goalProgressTotal {
        position: absolute;
        height: 100%;

        width: 60%;
        z-index: 9;

        .goalProgressLine {
            position: absolute;
            background-color: #ff9900;
            width: 4px;
            right: -5px;
            height: 52px;
            top: -35px;
            border-radius: 12px;
        }
    }

    .totalWorkProgress {
        position: absolute;
        height: 100%;
        // background: #3e97ff;
        width: 100%;
        z-index: 8;


        .totalWorkCount {
            position: absolute;
            height: 50px;
            background: rgb(35 209 93);
            width: 4px;
            right: -5px;
            top: -11px;
            border-radius: 12px;

            .totalWorkLabel {
                position: absolute;
                top: -25px;
                right: 0;
                width: 120px;
                text-align: right;
                color: rgb(134, 131, 131);
            }
        }

        .endDate {
            position: absolute;
            bottom: -40px;
            color: rgb(134, 131, 131);
            right: 0;
        }
    }
}

.progressInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;

    .completedInfo {
        display: flex;
        align-items: center;
        position: relative;

    }


    .circle {
        height: 13px;
        width: 13px;
        border-radius: 50%;
        position: absolute;
    }

    .dataInfo {
        margin-left: 17px;
        font-family: @primary-font;
        color: rgb(134, 131, 131);

        @media screen and(max-width:991px) {
            font-size: 1.3rem;
        }
    }

    .completedDateInfo {
        .date {
            font-family: @primary-font;
            color: rgb(134, 131, 131);

            @media screen and(max-width:991px) {
                font-size: 1.3rem;
            }
        }

        .dataInfo {
            margin-left: 5px;

            @media screen and(max-width:991px) {
                font-size: 1.3rem;
            }
        }
    }
}

.noDataProgress {
    height: 100%;
    background: white;
    border-radius: 8px;
    border: 1px solid #e3e0e0;
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
        font-family: @primary-font;
        font-size: 1.5rem;
    }
}

.progressBtnWrap {
    .ant-form-item {
        margin-bottom: 0;
        .ant-picker {
        height: 35px;
            border: 1px solid #e3e0e0;
            background-color: white !important;
            border-radius: 8px;
        }
    }
}
    @media screen and (max-height:860px) {
        height: calc(91vh - 20px);
    }

    @media screen and (max-height:768px) {
        height: calc(90vh - 20px);
    }

    @media screen and (max-height:690px) {
        height: calc(88vh - 20px);
    }
}